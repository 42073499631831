import { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  RecordContextProvider,
  ReferenceInput,
  required,
} from 'react-admin';
import useDialogContext from 'components/DialogContainer/useDialogContext';
import { DurationInput } from './DurationInput';

export const SubscriptionEditForm: FC = () => {
  const { record } = useDialogContext();
  //@TODO Invoice Id is not available in this record
  return (
    <RecordContextProvider value={record}>
      <ReferenceInput source="app_collection_id" reference="collections">
        <AutocompleteInput optionText="name" validate={required()} />
      </ReferenceInput>
      <DurationInput validate={required()} />
      <DateTimeInput source="valid_from" validate={required()} />
      <BooleanInput source="start_now" />
      <BooleanInput source="my_tover_access" />
    </RecordContextProvider>
  );
};
