import {
  TextField,
  BooleanInput,
  DateTimeInput,
  TextInput,
  useRecordContext,
  Labeled,
  useTranslate,
} from 'react-admin';
import { Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';

interface EditSoftwareReleaseFormProps {
  record?: any;
  resource?: string;
}

const ReleaseInputs = () => {
  const releaseNow = useWatch({ name: 'release_now' });

  return (
    <>
      <DateTimeInput source="released_at" fullWidth disabled={releaseNow} />
      <BooleanInput source="release_now" />
    </>
  );
};

export const EditSoftwareReleaseForm = (props: EditSoftwareReleaseFormProps) => {
  const record = useRecordContext(props);
  const t = useTranslate();
  return (
    <>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {t('resources.channel_releases.forms.edit.header')}
      </Typography>
      <Labeled>
        <TextField source="name" />
      </Labeled>
      <ReleaseInputs />
      <TextInput source="update_channel_id" type="hidden" defaultValue={record?.id} sx={{ display: 'none' }} />
    </>
  );
};
