import NoSimIcon from '@mui/icons-material/NoSim';
import SimCardIcon from '@mui/icons-material/SimCard';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useRecordContext } from 'react-admin';

interface SimCardFieldProps {
  source: string;
}
export const SimCardField: FC<SimCardFieldProps> = () => {
  const record = useRecordContext();
  return (
    <Tooltip
      title={
        record?.mobile_network?.sim_id
          ? record?.mobile_network?.state
            ? 'SIM card is active'
            : 'SIM card is inactive'
          : 'No SIM'
      }
    >
      {record?.mobile_network?.sim_id ? (
        <SimCardIcon color={record?.mobile_network?.state ? 'success' : 'warning'} />
      ) : (
        <NoSimIcon color="disabled" />
      )}
    </Tooltip>
  );
};
SimCardField.displayName = 'SimCardField';
