import { FC } from 'react';
import {
  ArrayField,
  Datagrid,
  EditButton,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useShowContext,
} from 'react-admin';

const ShowActions = () => {
  let showControllerResult = useShowContext();
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = showControllerResult;

  return <TopToolbar>{record?.editable && <EditButton />}</TopToolbar>;
};

export const SoftwareReleaseShow: FC<ShowProps> = () => {
  return (
    <Show actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField source="name" />
        <ReferenceField source="hardware_id" reference="hardware">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="hardware_revision_id" reference="hardware_revisions">
          <TextField source="name" emptyText="-" />
        </ReferenceField>
        <ArrayField source="software_components" resource="software_components">
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <ReferenceField source="software_version_id" reference="software_versions">
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
