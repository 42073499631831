import { Sensors } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { UpdateChannelCreate } from './UpdateChannelCreate';
import { UpdateChannelEdit } from './UpdateChannelEdit';
import { UpdateChannelList } from './UpdateChannelList';
import { UpdateChannelShow } from './UpdateChannelShow';
import { SubMenu } from '../../constants/SubMenu';

const UpdateChannelResource: ResourceProps = {
  name: 'update_channels',
  options: {
    isVisible: (hasPermission: Function) => hasPermission('dev_options'),
    menu: SubMenu.Develop,
  },
  icon: Sensors,
  list: UpdateChannelList,
  show: UpdateChannelShow,
  edit: UpdateChannelEdit,
  create: UpdateChannelCreate,

  recordRepresentation: (record) => {
    return record.name;
  },
};

export default UpdateChannelResource;
