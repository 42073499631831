import { IStringifyOptions } from 'qs';

export const defaultSettings = {
  headers: {
    'Content-Type': 'application/json',
  },
  initPage: 1,
  updateMethod: 'PUT',
  arrayFormat: 'brackets' as IStringifyOptions['arrayFormat'],
};
