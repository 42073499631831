import { Grid, Paper, Stack } from '@mui/material';
import { Edit, ReferenceArrayInput, ReferenceInput, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import HardwareRevisionField from 'resources/hardware-revisions/HardwareRevisionField';

export const HardwareEdit = () => {
  return (
    <Edit>
      <Stack>
        <SimpleForm>
          <Grid container flexDirection="row">
            <Grid container spacing={4} justifyContent="flex-end" sx={{ mt: 4, zIndex: 1 }} />
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Paper>
                  <HardwareEditView />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <HardwareRevisionField />
              </Grid>
            </Grid>
          </Grid>
        </SimpleForm>
      </Stack>
    </Edit>
  );
};

const HardwareEditView = () => {
  const record = useRecordContext();
  return (
    <>
      <TextInput source="name" />
      <TextInput source="short_name" />
      <ReferenceInput source="flavour_id" reference="flavours" />
      <TextInput source="serial_validation_rule" fullWidth />

      <ReferenceArrayInput
        source="software_component_ids"
        reference="software_components"
        filter={{ hardware_id: record?.id ?? 0 }}
        sort={{ field: 'name', order: 'ASC' }}
      />
    </>
  );
};
