import { Admin, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';

import { Layout, theme } from 'layout';
import { authProvider, dataProvider } from 'providers';

import AssetToUnit from 'components/AssetToUnit';
import Resource from 'components/Resource';
import { i18nProvider } from 'providers/i18n';
import { AccountEdit, ProfileProvider } from 'resources/account/AccountEdit';
import ActivityLogResource from 'resources/activity-logs';
import AppResource from 'resources/apps';
import SoftwareChannelReleaseResource from 'resources/channel-releases';
import ConfigurationResource from 'resources/configurations';
import CountryResource from 'resources/countries';
import Dashboard from 'resources/dashboard/Dashboard';
import DesignationResource from 'resources/designations';
import FlavourResource from 'resources/flavours';
import HardwareResource from 'resources/hardware';
import HardwareRevisionResource from 'resources/hardware-revisions';
import LocaleResource from 'resources/locales';
import PartnerGroupResource from 'resources/partner-groups';
import PartnerResource from 'resources/partners';
import PermissionResource from 'resources/permissions';
import PlaylistResource from 'resources/playlists';
import RegionResource from 'resources/regions';
import { default as ReportResource, default as Reports } from 'resources/reports';
import RoleResource from 'resources/roles';
import SoftwareReleaseResource from 'resources/software-releases';
import TelescopeExceptionsResource from 'resources/telescope/exceptions';
import UnitResource from 'resources/units';
import UpdateChannelResource from 'resources/update-channels';
import UserResource from 'resources/users';
import CollectionResource from './resources/collections';
import SoftwareComponentResource from './resources/software-components';
import SoftwareVersionResource from './resources/software-versions';
import SubscriptionDetailsResource from './resources/subscription-details';
import SubscriptionResource from './resources/subscriptions';
import SysConfigResource from './resources/sysconfig';
import TelescopeRequestsResource from './resources/telescope/requests';

const App = () => {
  return (
    <ProfileProvider>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        theme={theme}
        dashboard={Dashboard}
      >
        <Resource {...TelescopeExceptionsResource} />
        <Resource {...TelescopeRequestsResource} />
        <Resource {...UnitResource} />
        <Resource {...ConfigurationResource} />
        <Resource {...SubscriptionResource} />
        <Resource {...SubscriptionDetailsResource} />
        <Resource {...ActivityLogResource} />
        <Resource {...AppResource} />
        <Resource {...CollectionResource} />
        <Resource {...PlaylistResource} />
        <Resource {...DesignationResource} />
        <Resource {...FlavourResource} />
        <Resource {...LocaleResource} />
        <Resource {...PartnerResource} />
        <Resource {...PartnerGroupResource} />
        <Resource {...CountryResource} />
        <Resource {...RegionResource} />
        <Resource {...UserResource} />
        <Resource {...RoleResource} />
        <Resource {...PermissionResource} />
        <Resource {...ReportResource} />
        <Resource {...HardwareResource} />
        <Resource {...HardwareRevisionResource} />
        <Resource {...UpdateChannelResource} />
        <Resource {...SoftwareVersionResource} />
        <Resource {...SoftwareReleaseResource} />
        <Resource {...SoftwareComponentResource} />
        <Resource {...SoftwareChannelReleaseResource} />
        <Resource {...HardwareResource} />
        <Resource {...Reports} />
        <Resource {...SysConfigResource} />

        <Resource name="app_collection_trials" />
        <Resource name="app_collection_subscriptions" />
        <Resource name="app_collection_defaults" />
        <Resource name="app_purchases" />
        <Resource name="app_trials" />
        <Resource name="trial_durations" />
        <Resource name="display_surfaces" />

        <Resource name="account" />
        <CustomRoutes>
          <Route path="/account" element={<AccountEdit />} />
          <Route path="/asset/:id" element={<AssetToUnit />} />
          <Route path="/assets/:id" element={<AssetToUnit />} />
        </CustomRoutes>
      </Admin>
    </ProfileProvider>
  );
};

export default App;
