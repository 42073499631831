import { FC } from 'react';
import { BooleanInput, Edit, EditProps, required, TextInput } from 'react-admin';
import SimpleForm from 'components/SimpleForm';

export const DesignationEdit: FC<EditProps> = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <BooleanInput source="default" />
    </SimpleForm>
  </Edit>
);
