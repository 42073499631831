import React, { createContext, useContext, useMemo, useState } from 'react';

export const ProfileContext = createContext({});
export const ProfileProvider = ({ children }: any) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => {
          return currentVersion + 1;
        }),
    }),
    [profileVersion],
  );

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};
export const useProfile = () => useContext(ProfileContext);
