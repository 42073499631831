import { FC } from 'react';
import { DateField, DateFieldProps } from 'react-admin';

// TODO: custom moment.js component for relative time (e.g. x days ago)
export const FormattedDateField: FC<DateFieldProps> = ({ source, ...props }) => (
  <DateField
    {...props}
    source={source}
    showTime
    options={{
      hour12: false,
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }}
    emptyText="-"
  />
);
