import { FC } from 'react';
import { Datagrid, List, ListProps, ReferenceField, SearchInput, TextField } from 'react-admin';

const LocaleListFilters = [<SearchInput source="q" alwaysOn name="q" />];

export const LocaleList: FC<ListProps> = () => (
  <List perPage={25} filters={LocaleListFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="description" emptyText="-" />
      <ReferenceField source="fallback_id" reference="locales">
        <TextField source="name" emptyText="-" />
      </ReferenceField>
    </Datagrid>
  </List>
);
