import { ResourceProps } from 'react-admin';
import { VideoCameraFront as UnitIcon } from '@mui/icons-material';

import { Unit } from 'types';
import UnitList from './UnitList';
import UnitShow from './UnitShow';
import UnitEdit from './UnitEdit';
import UnitCreate from './UnitCreate';

const UnitResource: ResourceProps = {
  name: 'units',
  icon: UnitIcon,
  list: UnitList,
  show: UnitShow,
  edit: UnitEdit,
  create: UnitCreate,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_units'),
  },
  recordRepresentation: (record: Unit) => record.serial || `#${record.configuration_key}`,
};

export default UnitResource;
