import { FC } from 'react';
import { ReferenceField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';
import { ColorField } from 'react-admin-color-picker';

export const FlavourShow: FC<ShowProps> = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <ColorField source="color" />
      <TextField source="serial" emptyText="-" />
      <ReferenceField source="default_app_collection_id" reference="collections" emptyText="-">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
