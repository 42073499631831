import { AccountTree } from '@mui/icons-material';
import { SoftwareComponentList } from './SoftwareComponentList';
import { SubMenu } from '../../constants/SubMenu';

const SoftwareComponentResource = {
  name: 'software_components',
  options: {
    isVisible: (hasPermission: Function) => hasPermission('dev_options'),
    menu: SubMenu.Develop,
  },
  icon: AccountTree,
  list: SoftwareComponentList,
  recordRepresentation: (record: any) => record.name,
};

export default SoftwareComponentResource;
