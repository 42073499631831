import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { SubMenu } from 'constants/SubMenu';
import { ResourceProps } from 'react-admin';
import SysConfigEdit from './SysConfigEdit';

const Index: ResourceProps = {
  icon: SettingsSuggestIcon,
  list: SysConfigEdit,
  edit: SysConfigEdit,
  create: () => null,
  name: 'sysconfig',
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_sysconfig'),
    menu: SubMenu.Configuration,
  },
};

export default Index;
