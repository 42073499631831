import { ResourceProps } from 'react-admin';

import ActivityLogList from './ActivityLogList';
import { SubMenu } from '../../constants/SubMenu';

const ActivityLogResource: ResourceProps = {
  name: 'activity_logs',
  list: ActivityLogList,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('dev_options'),
    menu: SubMenu.Develop,
  },
};

export default ActivityLogResource;
