import { FC } from 'react';
import { BooleanInput, Create, EditProps, required, TextInput } from 'react-admin';
import SimpleForm from 'components/SimpleForm';

export const DesignationCreate: FC<EditProps> = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <BooleanInput source="default" />
    </SimpleForm>
  </Create>
);
