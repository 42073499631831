import { ResourceProps } from 'react-admin';

import * as t from 'types';
import { HardwareEdit } from './HardwareEdit';
import { HardwareList } from './HardwareList';
import { HardwareShow } from './HardwareShow';
import { SubMenu } from '../../constants/SubMenu';

const HardwareResource: ResourceProps = {
  name: 'hardware',
  recordRepresentation: (record: t.Hardware) => record.name,
  list: HardwareList,
  show: HardwareShow,
  edit: HardwareEdit,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('dev_options'),
    menu: SubMenu.Develop,
  },
};

export default HardwareResource;
