import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

interface Props {
  value?: number;
}

const LastOnlineUnits = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  return (
    <CardWithIcon
      to=""
      icon={CloudDoneIcon}
      title={translate('resources.dashboard.last_online_units', { _: 'Last online units', smart_count: 1 })}
      subtitle={value}
    />
  );
};

export default LastOnlineUnits;
