import { useEffect, useState } from 'react';
import { usePermissions as useRaPermissions } from 'react-admin';
import { useProfile } from './profileProvider';

export interface PropsWithPermissions {
  permissions: string[];
}

export interface PermissionState {
  [key: string]: boolean;
}

export function usePermission() {
  const { permissions, error, isLoading } = useRaPermissions();
  // @ts-ignore
  const { profileVersion } = useProfile();
  const [hasPerm, setHasPerm] = useState<PermissionState>({ profileVersion });
  useEffect(() => {
    setHasPerm({ profileVersion });
  }, [profileVersion]);

  const hasPermission = (required: string | RegExp, customPermissions?: Array<string>) => {
    const findPerm = (perm: string | RegExp) => {
      return required instanceof RegExp ? required.test(perm.toString()) : perm === required;
    };
    const checkPerm = (perms?: string[]) => (perms || []).findIndex(findPerm) >= 0;

    if (isLoading && (!permissions || !permissions.length)) return false;

    // @ts-ignore
    if (!hasPerm.hasOwnProperty(required.toString())) {
      setHasPerm({
        ...hasPerm,
        [required.toString()]: checkPerm(permissions),
      });
    }

    if (customPermissions?.length && !hasPerm[required.toString()]) {
      return checkPerm(customPermissions);
    }
    return hasPerm[required.toString()];
  };
  return {
    permissions,
    error,
    isLoading,
    hasPermission,
  };
}
