import { usePermission } from 'providers/auth/permissionProvider';
import { ComponentType, ReactElement, isValidElement } from 'react';
import { ResourceContextProvider, ResourceProps } from 'react-admin';
import { isValidElementType } from 'react-is';
import { Route, Routes } from 'react-router-dom';

interface ResourcePermissionProps {
  options?: {
    canCreate?: boolean | ((hasPermission: undefined | Function) => boolean | Promise<boolean> | undefined);
    canEdit?: boolean | ((hasPermission: undefined | Function) => boolean | Promise<boolean> | undefined);
    canShow?: boolean | ((hasPermission: undefined | Function) => boolean | Promise<boolean> | undefined);
    canList?: boolean | ((hasPermission: undefined | Function) => boolean | Promise<boolean> | undefined);
  };
}

const Resource = (props: ResourceProps & ResourcePermissionProps) => {
  const { hasPermission } = usePermission();
  const {
    create,
    edit,
    list,
    name,
    show,
    options: { canCreate = true, canEdit = true, canShow = true, canList = true } = {},
  } = props;

  return (
    <ResourceContextProvider value={name}>
      <Routes>
        {create && (typeof canCreate === 'function' ? canCreate(hasPermission) : canCreate) && (
          <Route path="create/*" element={getElement(create)} />
        )}
        {show && (typeof canShow === 'function' ? canShow(hasPermission) : canShow) && (
          <Route path=":id/show/*" element={getElement(show)} />
        )}
        {edit && (typeof canEdit === 'function' ? canEdit(hasPermission) : canEdit) && (
          <Route path=":id/*" element={getElement(edit)} />
        )}
        {list && (typeof canList === 'function' ? canList(hasPermission) : canList) && (
          <Route path="/*" element={getElement(list)} />
        )}
        {props.children}
      </Routes>
    </ResourceContextProvider>
  );
};

const getElement = (ElementOrComponent: ComponentType<any> | ReactElement) => {
  if (isValidElement(ElementOrComponent)) {
    return ElementOrComponent;
  }

  if (isValidElementType(ElementOrComponent)) {
    return <ElementOrComponent />;
  }

  return null;
};

Resource.raName = 'Resource';

Resource.registerResource = ({
  create,
  edit,
  icon,
  list,
  name,
  options,
  show,
  recordRepresentation,
  hasCreate,
  hasEdit,
  hasShow,
}: ResourceProps) => ({
  name,
  options,
  hasList: !!list,
  hasCreate: !!create || !!hasCreate,
  hasEdit: !!edit || !!hasEdit,
  hasShow: !!show || !!hasShow,
  icon,
  recordRepresentation,
});

export default Resource;
