import { Button, useGetResourceLabel, useTranslate } from 'react-admin';
import { Add as AddIcon } from '@mui/icons-material';
import useDialogContext from 'components/DialogContainer/useDialogContext';

interface AddSubItemProps {
  resource?: string;
  label?: string;
}

const AddSubItem = (props: AddSubItemProps) => {
  const { openCreateDialog, resource } = useDialogContext();
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();

  let label;

  if (props.label) {
    label = props.label;
  } else {
    label = t('custom.actions.add', {
      name: (getResourceLabel(resource, 1) || '').replace(/^\w/, (c) => c.toLowerCase()),
      Name: getResourceLabel(resource, 1) || '',
    });
  }

  return (
    <Button label={label} onClick={openCreateDialog} sx={{ width: '100%', maxWidth: 200 }} variant="contained">
      <AddIcon />
    </Button>
  );
};

export default AddSubItem;
