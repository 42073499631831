import { Translate as LocaleIcon } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { SubMenu } from 'constants/SubMenu';

import { LocaleEdit } from './LocaleEdit';
import { LocaleList } from './LocaleList';
import { LocaleShow } from './LocaleShow';

const LocaleResource: ResourceProps = {
  name: 'locales',
  icon: LocaleIcon,
  list: LocaleList,
  show: LocaleShow,
  edit: LocaleEdit,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_locales'),
    menu: SubMenu.Configuration,
  },

  recordRepresentation: (record) => {
    return record.name;
  },
};

export default LocaleResource;
