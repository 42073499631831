import { FC, useEffect, useState } from 'react';
import { SelectInput, Validator, useDataProvider, useRecordContext, useResourceContext } from 'react-admin';

interface DurationProps {
  dataSource?: string;
  source?: string;
  label?: string;
  validate?: Validator | Validator[];
}

export const DurationInput: FC<DurationProps> = ({
  dataSource = 'subscription_details',
  source = 'length',
  label = undefined,
  validate,
}) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const [choices, setChoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .get(dataSource)
      .then(({ data }: { data: any }) => setChoices(Object.values(data)))
      .finally(() => setIsLoading(false));
  }, [dataProvider, dataSource]);
  return (
    <SelectInput
      resource={resource}
      source={source}
      choices={choices}
      label={label}
      optionText="label"
      optionValue="id"
      isLoading={isLoading}
      fullWidth
      validate={validate}
      defaultValue={record?.duration}
    />
  );
};
