// https://github.com/marmelab/react-admin/blob/925408c86b665fa026c7798b4af10fc70127be86/examples/demo/src/layout/Menu.js

import { AppShortcut, Settings, SmartToy } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { usePermission } from 'providers/auth/permissionProvider';
import { FC, useMemo, useState } from 'react';
import { DashboardMenuItem, Menu, MenuProps, useResourceDefinitions } from 'react-admin';

import { SubMenu } from 'constants/SubMenu';
import { SubMenu as SubMenuComponent } from './SubMenu';

interface MenuState {
  [key: string]: boolean;
}

const CustomMenu: FC<MenuProps> = (props) => {
  const resources = useResourceDefinitions();
  const { permissions, isLoading, hasPermission } = usePermission();

  const [state, setState] = useState<MenuState>({
    [SubMenu.AppsAndCollections]: false,
    [SubMenu.Configuration]: false,
    [SubMenu.Software]: false,
    [SubMenu.Develop]: false,
  });

  const handleToggle = (menu: string) => {
    setState((state) => ({ [menu]: !state[menu] }));
  };

  const permittedResources = useMemo(() => {
    if (permissions) {
      const filteredResources = Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .filter((name) =>
          resources[name].options?.isVisible ? resources[name].options.isVisible(hasPermission) : true,
        );
      return filteredResources;
    }
  }, [permissions, resources, hasPermission]);

  if (isLoading) return <CircularProgress />;

  function getSubMenuComponent(item: SubMenu, title: string, icon: any) {
    return (
      <SubMenuComponent handleToggle={() => handleToggle(item)} isOpen={state[item]} name={title} icon={icon}>
        {permittedResources?.length &&
          permittedResources
            .filter((name) => resources[name].options?.menu === item)
            .map((name) => <Menu.ResourceItem key={name} name={name} />)}
      </SubMenuComponent>
    );
  }

  return (
    <Menu {...props}>
      {hasPermission('view_dashboard') && <DashboardMenuItem />}
      {permittedResources?.length &&
        permittedResources
          .filter((name) => !resources[name].options?.menu)
          .map((name) => <Menu.ResourceItem key={name} name={name} />)}
      {getSubMenuComponent(SubMenu.AppsAndCollections, 'Apps & Collections', <AppShortcut />)}
      {getSubMenuComponent(SubMenu.Configuration, 'Settings', <Settings />)}
      {getSubMenuComponent(SubMenu.Develop, 'Dev', <SmartToy />)}
    </Menu>
  );
};

export default CustomMenu;
