import { get } from 'lodash';
import { useRecordContext } from 'react-admin';

type TruncatedTextFieldProps = {
  source: string;
  maxLength?: number;
  label?: string;
};

const TruncatedTextField = ({ source, maxLength = 20, label }: TruncatedTextFieldProps) => {
  const record = useRecordContext();
  const str: string = get(record || {}, source, '');
  return str ? <span>{str.length > maxLength ? str.slice(0, maxLength) + '...' : str}</span> : null;
};
TruncatedTextField.defaultProps = { label: 'TruncatedTextField' };

export default TruncatedTextField;
