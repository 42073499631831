import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  RaRecord,
  ReferenceInput,
  required,
  TextInput,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { Box } from '@mui/material';

import { DurationInput } from './DurationInput';

interface SubscriptionCreateFormProps {
  record?: RaRecord;
  resource?: string;
}

export const SubscriptionCreateForm = (props: SubscriptionCreateFormProps) => {
  const resource = useResourceContext({ ...props, resource: 'app_collection_subscriptions' });
  const record = useRecordContext({ ...props, resource: 'app_collection_subscriptions' });
  return (
    <Box display="flex" flexDirection="column">
      <ReferenceInput source="app_collection_id" reference="collections">
        <AutocompleteInput optionText="name" resource={resource} validate={required()} />
      </ReferenceInput>
      <DurationInput validate={required()} />
      <DateTimeInput source="start_date" defaultValue={new Date()} resource={resource} validate={required()} />
      <BooleanInput source="start_now" resource={resource} />
      <BooleanInput source="my_tover_access" resource={resource} />
      <TextInput source="invoice_number" resource={resource} validate={required()} />
      <TextInput
        source="configuration_id"
        type="hidden"
        sx={{ display: 'none' }}
        defaultValue={record.configuration_key}
      />
    </Box>
  );
};
