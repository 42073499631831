import { pick } from 'lodash';
import React, { useState } from 'react';
import { useSaveContext } from 'react-admin';

const CustomTabbedFormContext = React.createContext<{
  handleSubmit: (values: any, event: any) => void;
  setGroupFields: (fields: string[]) => void;
}>({
  handleSubmit: (values: any, event: any) => {},
  setGroupFields: (fields: string[]) => {},
});
export const useCustomTabbedForm = () => React.useContext(CustomTabbedFormContext);
export const CustomTabbedFormProvider = (props: any) => {
  const { children } = props;
  const [groupFields, setGroupFields] = useState<string[]>([]); // Default to the first tab
  const saveContext = useSaveContext();

  const handleSubmit = (values: any, event: any) => {
    // remove all of fileds from values which are not in groupFields
    const newValues = pick(values, groupFields);

    return saveContext?.save && saveContext?.save(newValues, event);
  };

  const value = {
    handleSubmit: handleSubmit,
    setGroupFields: setGroupFields,
  };

  return <CustomTabbedFormContext.Provider value={value}>{children}</CustomTabbedFormContext.Provider>;
};
