import { FC } from 'react';
import { EditBase, RaRecord, useGetResourceLabel, useNotify, useResourceContext, useTranslate } from 'react-admin';
import { DialogProps } from '@mui/material';

import { FormDialog } from 'components';
import useDialogContext from 'components/DialogContainer/useDialogContext';

interface EditDialogProps extends DialogProps {
  resource?: string;
  record?: RaRecord;
  title?: string;
  children: React.ReactNode;
}

// TODO: make more generic (REFACTOR partner group edit as well?)

export const EditDialog: FC<EditDialogProps> = (props) => {
  const { isEditing, record } = useDialogContext();
  if (!isEditing || !record) {
    return null;
  }

  return <EditDialogView {...props} />;
};

interface EditDialogViewProps {
  children: React.ReactNode;
  title?: string;
  open?: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  resource?: string;
}

const EditDialogView = (props: EditDialogViewProps) => {
  const { isEditing, record, closeDialog } = useDialogContext();

  const { title: customTitle, children } = props;
  const notify = useNotify();
  const t = useTranslate();
  const getResourceLabel = useGetResourceLabel();
  const resource = useResourceContext(props);

  const handleSuccess = () => {
    notify('ra.notification.updated', { messageArgs: { smart_count: 1 } });
    if (closeDialog) closeDialog();
  };
  let title;

  if (customTitle) {
    title = customTitle;
  } else {
    title = t('custom.actions.edit', {
      name: getResourceLabel(resource, 1).replace(/^\w/, (c) => c.toLowerCase()),
      Name: getResourceLabel(resource, 1),
    });
  }
  return (
    <EditBase
      id={record.id}
      resource={resource}
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess: handleSuccess }}
    >
      <FormDialog title={title} open={!!isEditing} onClose={closeDialog}>
        {children}
      </FormDialog>
    </EditBase>
  );
};

export default EditDialog;
