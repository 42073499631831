import { Box } from '@mui/material';
import {
  Link,
  RaRecord,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

interface SubscriptionCreateFormProps {
  record?: RaRecord;
  resource?: string;
}

export const HardwareRevisionCreateForm = (props: SubscriptionCreateFormProps) => {
  const resource = useResourceContext({ ...props, resource: 'hardware_revisions' });
  const record = useRecordContext({ ...props, resource: 'hardware_revisions' });
  console.debug(record);
  return (
    <Box display="flex" flexDirection="column">
      <TextInput
        source="hardware_id"
        type="hidden"
        sx={{ display: 'none' }}
        defaultValue={record.id}
        resource={resource}
      />
      <TextInput source="name" validate={required()} helperText={false} fullWidth resource={resource} />
      <TextInput source="rule" helperText={false} fullWidth resource={resource} />

      <ReferenceInput
        source="update_channel_id"
        reference="update_channels"
        filter={{ hardware_revision_id: record.id }}
        resource={resource}
      >
        <SelectInput optionText="name" fullWidth />
      </ReferenceInput>
      <Link target="_blank" to={'https://regex101.com/?regex=' + record.rule}>
        Test at Regex101
      </Link>
    </Box>
  );
};
