import { FC } from 'react';
import { Datagrid, List, ListProps, ReferenceField, TextField } from 'react-admin';

export const HardwareRevisionList: FC<ListProps> = () => (
  <List perPage={25}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="hardware_id" reference="hardware" emptyText="-">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
    </Datagrid>
  </List>
);
