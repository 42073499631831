import axios, { AxiosInstance } from 'axios';
import { stringify, IStringifyOptions } from 'qs';
import { GetManyParams } from 'react-admin';

export const getQueryFromParams = (params: { pagination?: any; filter?: any; offsetPageNum?: any; sort?: any }) => {
  const { offsetPageNum, sort } = params;
  const { page, perPage } = params.pagination;

  let currPage = page;
  if (!isNaN(parseInt(offsetPageNum, 10))) {
    currPage += parseInt(offsetPageNum, 10);
  }

  // Create query with pagination params.
  const query: Record<string, unknown> = {
    page: currPage,
    perPage,
    sort,
  };

  // Add all filter params to query.
  Object.keys(params.filter || {}).forEach((key) => {
    query[`filter[${key}]`] = params.filter[key];
  });

  return query;
};

export const getIds = (params: GetManyParams, arrayFormat?: IStringifyOptions['arrayFormat']) => {
  return stringify(
    {
      'filter[id]': params.ids,
    },
    { arrayFormat },
  );
};

export const createClient = (apiUrl: string, settings: Record<any, unknown>) => {
  return axios.create({
    baseURL: apiUrl,
    ...settings,
  });
};

export const setInterceptors = (instance: AxiosInstance, tokenName: string) => {
  // Request interceptor
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(tokenName);
      const newConfig = config;

      // When a 'token' is available set as Bearer token.
      if (token) {
        // @ts-ignore Authorization header can be set up without a problem
        newConfig.headers.Authorization = `Bearer ${token}`;
      }

      return newConfig;
    },
    (err) => Promise.reject(err),
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (response.data.meta && response.data.meta.total) {
        response.data.total = response.data.meta.total;
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
};
