import { Box, Paper, Typography } from '@mui/material';
import DialogContainer from 'components/DialogContainer';
import AddSubItem from 'components/SimpleForm/AddSubtem';
import EditSubItem from 'components/SimpleForm/EditSubItem';
import {
  Datagrid,
  DeleteWithConfirmButton,
  FunctionField,
  RaRecord,
  ReferenceArrayField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { HardwareRevisionCreateForm } from './HardwareRevisionCreateForm';
import { HardwareRevisionEditForm } from './HardwareRevisionEditForm';

const HardwareRevisionField = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }}>
        {translate('resources.hardware.section.hardware_revisions')}
      </Typography>
      <DialogContainer
        createForm={<HardwareRevisionCreateForm record={record} />}
        editForm={<HardwareRevisionEditForm />}
        resource="hardware_revisions"
      >
        <ReferenceArrayField reference="hardware_revisions" source="hardware_revision_ids" fullWidth>
          <Paper>
            <Datagrid
              empty={
                <Box sx={{ p: 2 }}>
                  <Typography variant="body2">{translate('resources.hardware_revisions.empty')}</Typography>
                </Box>
              }
              bulkActionButtons={false}
              sx={{
                '.RaDatagrid-row': {
                  'th:last-of-type, td:last-of-type': { textAlign: 'center', maxWidth: '80px' },
                },
              }}
            >
              <TextField source="name" fullWidth />
              <TextField source="rule" fullWidth />
              <FunctionField
                label="custom.labels.actions"
                render={(record: RaRecord) => (
                  <Box display="flex">
                    <EditSubItem />
                    <DeleteWithConfirmButton mutationMode="optimistic" redirect={false} label="" />
                  </Box>
                )}
              />
            </Datagrid>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
              <AddSubItem />
            </Box>
          </Paper>
        </ReferenceArrayField>
      </DialogContainer>
    </>
  );
};

export default HardwareRevisionField;
