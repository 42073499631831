import { FC } from 'react';
import {
  AutocompleteArrayInput,
  ChipField,
  Datagrid,
  List,
  ListProps,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SingleFieldList,
  TextField,
} from 'react-admin';
import FlavourChipField from '../flavours/FlavourChipField';
import IconField from '../../components/Fields/IconField';
import SurfaceIcon from '../surfaces/icons/SurfaceIcon';

const AppListFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="flavour_id" reference="flavours">
    <SelectInput source="name" optionText={<FlavourChipField />} emptyText="All" />
  </ReferenceInput>,
  <ReferenceArrayInput source="designation.id" reference="designations">
    <AutocompleteArrayInput source="name" optionText={(option) => option.name} />
  </ReferenceArrayInput>,
];

const AppList: FC<ListProps> = () => (
  <List perPage={25} filters={AppListFilters}>
    <Datagrid
      rowClick="show"
      sx={{
        '& .RaDatagrid-headerCell': {
          'whiteSpace': 'nowrap',
          '&:nth-of-type(2)': { width: 60 },
        },
      }}
      bulkActionButtons={false}
    >
      <IconField source="icon.src" />
      <TextField source="name" />
      <TextField source="translated_name" />
      <NumberField source="cognitive" />
      <ReferenceArrayField source="flavour_ids" reference="flavours" emptyText="-">
        <SingleFieldList linkType="show">
          <FlavourChipField />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="designation_ids" reference="designations" emptyText="-">
        <SingleFieldList>
          <ChipField source="name" sortable={false} />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="display_surface_ids" reference="display_surfaces" emptyText="-">
        <SingleFieldList linkType="show">
          <SurfaceIcon source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export default AppList;
