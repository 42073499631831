import { FC } from 'react';
import { Datagrid, List, ReferenceField, ReferenceInput, SearchInput, SelectInput, TextField } from 'react-admin';

const updateChannelFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="hardware_id" reference="hardware" alwaysOn>
    <SelectInput source="name" />
  </ReferenceInput>,
];

export const UpdateChannelList: FC = () => {
  return (
    <List filters={updateChannelFilters} hasCreate filterDefaultValues={{ hardware_id: 1 }}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" />
        <ReferenceField source="hardware_revision_id" reference="hardware_revisions">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="software_release_id" reference="software_releases" emptyText="-" link="show">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
