import { FC } from 'react';
import {
  Datagrid,
  Filter,
  FilterProps,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  useListContext,
} from 'react-admin';
import { FormattedDateField } from '../../components/FormattedDateField';

const SoftwareVersionFilters: FC<Omit<FilterProps, 'children'>> = (props) => {
  const { filterValues } = useListContext();

  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <ReferenceInput source="hardware_id" reference="hardware">
        <SelectInput source="name" emptyText="ra.action.select_all" />
      </ReferenceInput>
      <ReferenceInput
        source="software_component_id"
        reference="software_components"
        target="software_component_id"
        filter={{ hardware_id: filterValues.hardware_id }}
      >
        <SelectInput source="name" optionText="name" emptyText="ra.action.select_all" />
      </ReferenceInput>
    </Filter>
  );
};

export const SoftwareVersionList: FC = () => {
  return (
    <List filters={<SoftwareVersionFilters />}>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="version" />
        <ReferenceField source="hardware_id" reference="hardware" emptyText="-">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="software_component_id" reference="software_components" emptyText="-">
          <TextField source="name" />
        </ReferenceField>
        <FormattedDateField source="created_at" />
      </Datagrid>
    </List>
  );
};
