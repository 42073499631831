import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { usePermission } from 'providers/auth/permissionProvider';
import { useNotify, useGetList, FormTab } from 'react-admin';
import { Designation } from 'types';

type ProtectProps = {
  permissions?: string | RegExp | string[] | RegExp[] | (string | RegExp)[];
  children: React.ReactElement;
  part?: string;
  hasAny?: Array<string | RegExp>;
};

const Protect = (props: ProtectProps) => {
  const { permissions, children, part, hasAny, ...rest } = props;
  const notify = useNotify();
  const designationList = useGetList<Designation>('designations', {
    pagination: { page: 1, perPage: 25 },
    sort: { field: 'name', order: 'ASC' },
  }).data;

  const designations = useMemo(() => {
    if (!designationList) return {};
    const ret: { [id: number]: string } = {};
    designationList.forEach((designation) => {
      return (ret[designation.id] = designation.name.toLowerCase());
    });
    return ret;
  }, [designationList]);

  const designation_id: number | null = useWatch({ name: 'designation_id' });
  const { hasPermission } = usePermission();
  const designation = designation_id ? designations[designation_id] : null;

  const canManage = permissions
    ? Array.isArray(permissions)
      ? permissions.every((permission) => hasPermission(permission))
      : hasPermission(permissions)
    : true;

  const canManageAny = hasAny ? hasAny.some((permission) => hasPermission(permission)) : true;

  if (part && designationList && designationList.length && !designation_id) {
    setTimeout(() => {
      notify('resources.units.notifications.designation_not_set', {
        type: 'error',
        undoable: true,
        autoHideDuration: 30000,
      });
    }, 1);
    return React.cloneElement(children, { disabled: true });
  }

  const canWritePart = part && designation_id ? hasPermission(`${designation} unit ${part} write`) : true;
  const can = canManageAny && canManage && canWritePart;
  const childDisabled = children.props.disabled || children.props.children?.props?.disabled;

  let disabled: boolean = !can;
  if (childDisabled !== undefined) {
    disabled = disabled && childDisabled;
  }

  if (children.type === FormTab && disabled) {
    return null;
  }

  return React.cloneElement(children, { disabled, ...rest });
};

export default Protect;
