import { Fragment, ReactNode } from 'react';
import { Collapse, Divider, List, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface SubMenuProps {
  handleToggle: any;
  isOpen: boolean;
  name: string;
  icon: JSX.Element;
  classes?: unknown;
  children?: ReactNode;
}

export const SubMenu = ({ handleToggle, isOpen, name, icon, children }: SubMenuProps) => (
  <Fragment>
    <MenuItem onClick={handleToggle}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </MenuItem>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List component="div">{children}</List>
      <Divider />
    </Collapse>
  </Fragment>
);
