// @ts-ignore
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import {
  PasswordInput,
  SaveContextProvider,
  TextInput,
  Title,
  useDataProvider,
  useGetIdentity,
  useNotify,
} from 'react-admin';
import { AccountPayload } from 'types/Account';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import SimpleForm from 'components/SimpleForm';

/*
  Based on an outdated guide and v4 docs
  v2  https://marmelab.com/blog/2019/03/07/react-admin-advanced-recipes-user-profile.html
  v3  https://marmelab.com/blog/2020/12/14/react-admin-v3-userprofile.html
*/

type ProfileContextType = {
  profileVersion: number;
  refreshProfile: () => void;
};

const ProfileContext = createContext<ProfileContextType>({} as ProfileContextType);

export const ProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () => setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion],
  );

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => useContext(ProfileContext);

// unused { staticContext, ...props }
export const AccountEdit = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState<boolean>();
  const { refreshProfile } = useProfile();

  const { identity, isLoading } = useGetIdentity();

  const handleSave = useCallback(
    (values: AccountPayload) => {
      setSaving(true);

      try {
        dataProvider.updateUserProfile({ ...values });
        notify('Your profile has been updated', { type: 'info' });
        refreshProfile();
      } catch (error) {
        console.log(error);
        notify('A technical error occured while updating your profile. Please try later.', { type: 'warning' });
      } finally {
        setSaving(false);
      }
    },
    [dataProvider, notify, refreshProfile],
  );

  // custom 'SaveContext' to override the default 'save' callback
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave],
  );

  // Despite @hookform/resolvers patch, still seems to need typing
  // https://github.com/react-hook-form/resolvers/releases/tag/v3.1.1
  const schema: yup.ObjectSchema<FieldValues> = yup
    .object()
    .shape({
      name: yup.string(),
      email: yup.string().email(),
      new_password: yup.string().min(8).nullable(),
      new_password_confirmation: yup.string().when('new_password', {
        is: (val?: string) => !!val === true,
        then: (schema) => schema.oneOf([yup.ref('new_password')]).required(),
      }),
      old_password: yup.string().when('new_password', {
        is: (val?: string) => !!val === true,
        then: (schema) => schema.min(8).required(),
      }),
    })
    .required();

  if (isLoading) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <Card>
        <Title title="Edit account" />
        <SimpleForm onSubmit={handleSave} defaultValues={identity} resolver={yupResolver(schema)}>
          <TextInput source="name" />
          <TextInput source="email" />
          <PasswordInput source="old_password" />
          <PasswordInput source="new_password" />
          <PasswordInput source="new_password_confirmation" />
        </SimpleForm>
      </Card>
    </SaveContextProvider>
  );
};
