import { ResourceProps } from 'react-admin';

import * as t from 'types';
import { HardwareRevisionShow } from './HardwareRevisionShow';
import { HardwareRevisionEdit } from './HardwareRevisionEdit';
import { HardwareRevisionList } from './HardwareRevisionList';
import { SubMenu } from '../../constants/SubMenu';

const HardwareResource: ResourceProps = {
  name: 'hardware_revisions',
  recordRepresentation: (record: t.HardwareRevision) => record.name,
  show: HardwareRevisionShow,
  edit: HardwareRevisionEdit,
  list: HardwareRevisionList,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('dev_options'),
    menu: SubMenu.Develop,
  },
};

export default HardwareResource;
