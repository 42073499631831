import { FC } from 'react';
import { BooleanField, BooleanInput, Datagrid, List, ListProps, SearchInput, TextField } from 'react-admin';

const CountryListFilters = [
  <SearchInput source="q" name="q" alwaysOn />,
  <BooleanInput source="active" name="active" alwaysOn />,
];

export const CountryList: FC<ListProps> = () => (
  <List perPage={25} filters={CountryListFilters} filterDefaultValues={{ active: true }}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);
