import { ImageField } from 'react-admin';

type IconProps = {
  source: string;
  size?: number;
};

const IconField = (props: IconProps) => {
  const size = props.size ?? 60;
  return (
    <ImageField
      label="custom.labels.icon"
      source={props.source}
      sx={{
        '& img': {
          minWidth: size,
          maxWidth: size,
          minHeight: size,
          maxHeight: size,
          objectFit: 'contain',
        },
      }}
    />
  );
};
export default IconField;
