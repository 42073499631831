import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  FilterPayload,
  FormDataConsumer,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  ToolbarProps,
  useGetList,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

import SimpleForm from 'components/SimpleForm';
import { SoftwareComponent, SoftwareVersion } from 'types';
import { useSearchParams } from 'react-router-dom';

const SoftwareComponentInput = () => {
  const id: number = useWatch({ name: 'hardware_id' });
  const [searchParams] = useSearchParams();

  const { data, isLoading } = useGetList<SoftwareComponent>('software_components', {
    filter: {
      hardware_id: id,
    },
  });

  if (!id || isLoading) return null;

  const clonedSoftwareVersionParam = searchParams.get('source');

  return (
    <Box sx={{ width: '100%' }}>
      <Stack>
        {data?.map((component) => {
          const defaultValue =
            clonedSoftwareVersionParam &&
            JSON.parse(clonedSoftwareVersionParam)
              .software_components?.filter((c: any) => c.id === component.id && c.hardware_id === id)
              .shift();
          return <SoftwareVersionInput component={component} defaultValue={defaultValue?.software_version_id} />;
        })}
      </Stack>
    </Box>
  );
};
const SoftwareVersionInput = ({
  component,
  defaultValue,
}: {
  component: SoftwareComponent;
  defaultValue: SoftwareVersion;
}) => {
  const isCustom = useWatch({ name: 'custom' });
  const id: number = useWatch({ name: 'hardware_id' });

  const filter: FilterPayload = { hardware_id: id, software_component_id: component.id };

  if (!isCustom) {
    filter.type = 'tag';
  }

  return (
    <ReferenceInput source={component.name} reference="software_versions" filter={filter}>
      <AutocompleteInput validate={required()} optionText="name" defaultValue={defaultValue} />
    </ReferenceInput>
  );
};

const SoftwareReleaseCreateToolbar = (props: ToolbarProps) => {
  const id: number = useWatch({ name: 'hardware_id' });
  const { data: components, isLoading } = useGetList<SoftwareComponent>('software_components', {
    filter: {
      hardware_id: id,
    },
  });

  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={!id || isLoading}
        transform={({ tover, ...rest }) => {
          return {
            ...rest,
            software_components: components?.map((component) => {
              return {
                ...component,
                software_version_id: tover[component.name.split('.')[1]],
              };
            }),
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const SoftwareReleaseCreate: FC<CreateProps> = () => {
  const [searchParams] = useSearchParams();
  const clonedRelease = searchParams.has('source');

  return (
    <Create>
      <SimpleForm toolbar={<SoftwareReleaseCreateToolbar />}>
        <Typography variant="h4">Create release</Typography>
        <TextInput source="name" fullWidth validate={required()} />
        <ReferenceInput source="hardware_id" reference="hardware">
          <AutocompleteInput
            optionText="name"
            fullWidth
            readOnly={clonedRelease}
            validate={required()}
            helperText={clonedRelease && 'clone: read only'}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.hardware_id && (
              <ReferenceInput
                source="hardware_revision_id"
                reference="hardware_revisions"
                filter={{ hardware_id: formData.hardware_id }}
              >
                <SelectInput optionText="name" fullWidth validate={required()} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <BooleanInput source="custom" />
        <Typography variant="h5">Software Components</Typography>
        <SoftwareComponentInput />
      </SimpleForm>
    </Create>
  );
};
