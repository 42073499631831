import { FC } from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { AppCollectionOptionRenderer } from './AppCollectionOptionRenderer';

export const AppCollectionsField: FC = () => {
  const t = useTranslate();
  return (
    <Grid item>
      <Typography variant="h6">{t('resources.units.section.app_collections')}</Typography>
      <ReferenceArrayInput source="app_collection_ids" reference="collections">
        <AutocompleteArrayInput
          label={false}
          optionText={<AppCollectionOptionRenderer />}
          inputText={(option) => option.name}
          matchSuggestion={(filter, choice) => choice.name.toLowerCase().includes(filter.toLowerCase())}
        />
      </ReferenceArrayInput>
    </Grid>
  );
};
