import { Groups as UserIcon } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';

const UserResource: ResourceProps = {
  name: 'users',
  icon: UserIcon,
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_users'),
  },
  recordRepresentation: (record) => {
    return record.name;
  },
};

export default UserResource;
