import { UploadFile } from '@mui/icons-material';
import { SoftwareVersionList } from './SoftwareVersionList';
import { SubMenu } from '../../constants/SubMenu';

const SoftwareVersionResource = {
  name: 'software_versions',
  options: {
    isVisible: (hasPermission: Function) => hasPermission('dev_options'),
    menu: SubMenu.Develop,
  },
  icon: UploadFile,
  list: SoftwareVersionList,
};

export default SoftwareVersionResource;
