import { jwtDecode as decodeJwt } from 'jwt-decode';
import { AuthProvider } from 'react-admin';

export const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(`${process.env.REACT_APP_AUTH_URL}`, {
      method: 'POST',
      body: JSON.stringify({
        grant_type: process.env.REACT_APP_AUTH_GRANT_TYPE,
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        scope: process.env.REACT_APP_AUTH_SCOPE,
        username,
        password,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token, expires_in, refresh_token }) => {
        // @ts-ignore
        localStorage.setItem('permissions', JSON.stringify(decodeJwt(access_token).permissions));
        localStorage.setItem('token', `${access_token}`);
        localStorage.setItem('refresh', `${refresh_token}`);
        localStorage.setItem('expires', new Date().getTime() + expires_in);
        return Promise.resolve();
      })
      .catch(() => {
        throw new Error('Network error');
      });
  }, // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('expires');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  }, // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem('permissions');
      localStorage.removeItem('expires');
      return Promise.reject();
    }
    return Promise.resolve();
  }, // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const token = localStorage.getItem('token');
    const expires = localStorage.getItem('token');
    if (token) {
      if (new Date().getTime() > Number(expires)) {
        return Promise.reject();
      }
      return Promise.resolve();
    }
    return Promise.reject();
  },
  getIdentity: async () => {
    const request = new Request(`${process.env.REACT_APP_API_URL}/account`, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.token}`,
      }),
    });

    if (sessionStorage.getItem('profile')) {
      const { name, email } = JSON.parse(sessionStorage['profile']);
      return Promise.resolve({ name, email });
    } else {
      return await fetch(request)
        .then((response) => Promise.resolve(response.json()))
        .catch((error) => Promise.reject(error));
    }
    // called when the user navigates to a new location, to check for permissions / roles
  },
  getPermissions: () => {
    const role = JSON.parse(localStorage.getItem('permissions') || '[]');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
