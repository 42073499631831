import { FC } from 'react';
import { TextInput, required, email, ReferenceInput, AutocompleteInput, useRecordContext, RaRecord } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Box } from '@mui/material';

interface PartnerGroupFormProps {
  record?: RaRecord;
  resource?: string;
}

export const PartnerGroupForm: FC<PartnerGroupFormProps> = (props) => {
  const countryId = useWatch({ name: 'country_id' });
  const record = useRecordContext(props);

  return (
    <Box sx={{ maxWidth: 400, width: '100%' }}>
      <TextInput source="name" validate={required()} helperText={false} fullWidth />
      <TextInput source="account_manager" helperText={false} fullWidth />
      <TextInput source="service_email" validate={email()} defaultValue="" helperText={false} fullWidth />
      <ReferenceInput source="country_id" reference="countries">
        <AutocompleteInput
          source="name"
          optionText={(option) => option.name}
          validate={required()}
          helperText={false}
          fullWidth
        />
      </ReferenceInput>
      {countryId && (
        <ReferenceInput source="region_id" reference="regions" filter={{ country_id: countryId }}>
          <AutocompleteInput
            source="name"
            optionText={(option) => option.name}
            validate={required()}
            helperText={false}
            fullWidth
          />
        </ReferenceInput>
      )}
      <TextInput source="partner_id" type="hidden" sx={{ display: 'none' }} defaultValue={record?.id} />
    </Box>
  );
};
