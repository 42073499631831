import { FC } from 'react';
import { AutocompleteInput, RaRecord, ReferenceInput, TextInput } from 'react-admin';

import { AppCollectionOptionRenderer } from './AppCollectionOptionRenderer';
import { DurationInput } from './DurationInput';

interface CollectionTrialCreateProps {
  record?: RaRecord;
}

export const TrialCollectionsForm: FC<CollectionTrialCreateProps> = ({ record }) => {
  return (
    <div>
      <ReferenceInput source="app_collection_id" reference="collections">
        <AutocompleteInput
          optionText={<AppCollectionOptionRenderer />}
          inputText={(option) => option.name}
          matchSuggestion={(filter, choice) => choice.name?.toLowerCase().includes(filter.toLowerCase())}
        />
      </ReferenceInput>
      <DurationInput dataSource="trial_durations" />
      <TextInput
        source="configuration_id"
        type="hidden"
        sx={{ display: 'none' }}
        defaultValue={record?.configuration_key}
      />
    </div>
  );
};
