import { TextInput, required, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

interface RegionCreateFormProps {
  record?: any;
  resource?: string;
}

export const RegionCreateForm = (props: RegionCreateFormProps) => {
  const record = useRecordContext(props);
  return (
    <>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Please provide a name to the newly created region for this country.
      </Typography>
      <TextInput source="name" validate={required()} defaultValue="" helperText={false} fullWidth />
      <TextInput source="country_id" type="hidden" sx={{ display: 'none' }} defaultValue={record?.id} />
    </>
  );
};
