import { Flag as DesignationIcon } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { SubMenu } from 'constants/SubMenu';

import { DesignationCreate } from './DesignationCreate';
import { DesignationEdit } from './DesignationEdit';
import { DesignationList } from './DesignationList';
import { DesignationShow } from './DesignationShow';

const DesignationResource: ResourceProps = {
  name: 'designations',
  icon: DesignationIcon,
  list: DesignationList,
  show: DesignationShow,
  edit: DesignationEdit,
  create: DesignationCreate,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_designations'),
    menu: SubMenu.Configuration,
  },

  recordRepresentation: (record) => {
    return record.name;
  },
};

export default DesignationResource;
