import { get } from 'lodash';
import { FieldProps, useRecordContext } from 'react-admin';

function timeago(dateString: string) {
  const now = new Date().getTime();
  const past = new Date(`${dateString}`).getTime();
  const diffInSeconds = Math.floor((now - past) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s ago`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays}d ago`;
}

const TimeAgoField = ({ source }: FieldProps) => {
  const record = useRecordContext();
  const date = get(record, source || 'created_at');
  return <span>{timeago(date)}</span>;
};

TimeAgoField.defaultProps = { label: 'Time ago', sortable: false };

export default TimeAgoField;
