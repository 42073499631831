import { FC } from 'react';
import {
  AutocompleteInput,
  ChipField,
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from 'react-admin';
import OnlineStatusField from './OnlineStatusField';
import { SimCardField } from './SimCardField';

const UnitListFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="designation_id" reference="designations">
    <SelectInput source="name" optionText={(option) => option.name} emptyText="ra.action.select_all" />
  </ReferenceInput>,
  <ReferenceInput source="locale_id" reference="locales">
    <SelectInput source="name" optionText={(option) => option.name} emptyText="ra.action.select_all" />
  </ReferenceInput>,
  <ReferenceInput source="partner_id" reference="partners">
    <AutocompleteInput source="name" optionText={(option) => option.name} />
  </ReferenceInput>,
  <ReferenceInput source="country_id" reference="countries">
    <AutocompleteInput source="name" optionText={(option) => option.name} />
  </ReferenceInput>,
  <ReferenceInput source="collections" reference="collections">
    <AutocompleteInput source="name" optionText={(option) => option.name} />
  </ReferenceInput>,
  <ReferenceInput source="hardware_id" reference="hardware">
    <SelectInput source="name" optionText={(option) => option.name} emptyText="ra.action.select_all" />
  </ReferenceInput>,
  <SelectInput
    source="online_status"
    choices={[
      { name: 'Online', id: 'online' },
      { name: 'Realtime', id: 'realtime' },
      { name: 'Offline', id: 'offline' },
    ]}
  />,
  <SelectInput
    source="hasSimCard"
    choices={[
      { name: 'Has sim card', id: true },
      { name: "Doesn't have sim card", id: false },
    ]}
  />,
];

const UnitList: FC<ListProps> = () => (
  <List perPage={25} filters={UnitListFilters}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={{
        '& .column-sim_id': { width: '3em' },
        '& .column-online_status': { width: '3em' },
      }}
    >
      <TextField source="serial" />
      <ReferenceField reference="hardware" source="hardware_id" emptyText="-">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="hostname" emptyText="-" />
      <ReferenceField source="partner_id" reference="partners" emptyText="-" link="show">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField source="designation_id" reference="designations" emptyText="-" link="show">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField source="update_channel_id" reference="update_channels">
        <TextField source="name" />
      </ReferenceField>
      <OnlineStatusField source="online_status" />
      <SimCardField source="sim_id" />
    </Datagrid>
  </List>
);

export default UnitList;
