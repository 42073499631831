import { ResourceProps } from 'react-admin';

import * as t from 'types';

const PartnerGroupResource: ResourceProps = {
  name: 'partner_groups',
  recordRepresentation: (record: t.PartnerGroup) => record.name,
};

export default PartnerGroupResource;
