import { FC } from 'react';
import {
  Datagrid,
  List,
  ListProps,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import FlavourChipField from '../flavours/FlavourChipField';
import SurfaceIcon from '../surfaces/icons/SurfaceIcon';

export const PlaylistList: FC<ListProps> = () => (
  <List perPage={25}>
    <Datagrid
      rowClick="show"
      sx={{
        '& .RaDatagrid-headerCell': {
          'whiteSpace': 'nowrap',
          '&:nth-of-type(2)': { width: 60 },
        },
      }}
      bulkActionButtons={false}
    >
      <TextField source="key" />
      <ReferenceArrayField source="flavour_ids" reference="flavours" emptyText="-">
        <SingleFieldList linkType="show">
          <FlavourChipField />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField source="display_surface_id" reference="display_surfaces" emptyText="-">
        <SurfaceIcon source="name" />
      </ReferenceField>
      <NumberField source="apps_count" />
    </Datagrid>
  </List>
);
