import { Contacts as PartnerIcon } from '@mui/icons-material';

import { ResourceProps } from 'react-admin';
import { SubMenu } from 'constants/SubMenu';

import { PartnerEdit } from './PartnerEdit';
import { PartnerList } from './PartnerList';
import { PartnerShow } from './PartnerShow';

const PartnerResource: ResourceProps = {
  name: 'partners',
  icon: PartnerIcon,
  list: PartnerList,
  show: PartnerShow,
  edit: PartnerEdit,

  recordRepresentation: (record) => {
    return record.name;
  },
  options: {
    menu: SubMenu.Configuration,
  },
};

export default PartnerResource;
