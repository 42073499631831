import { Typography, Paper, Box } from '@mui/material';
import DialogContainer from 'components/DialogContainer';
import AddSubItem from 'components/SimpleForm/AddSubtem';
import EditSubItem from 'components/SimpleForm/EditSubItem';
import {
  ReferenceArrayField,
  Datagrid,
  TextField,
  FunctionField,
  RaRecord,
  DeleteWithConfirmButton,
  useTranslate,
  useRecordContext,
} from 'react-admin';
import { PartnerGroupForm } from './PartnerGroupForm';

const PartnerGroupField = () => {
  const t = useTranslate();
  const record = useRecordContext();
  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }}>
        {t('resources.partners.section.partner_groups')}
      </Typography>
      <DialogContainer
        createForm={<PartnerGroupForm record={record} />}
        editForm={<PartnerGroupForm />}
        resource="partner_groups"
      >
        <ReferenceArrayField reference="partner_groups" source="partner_group_ids" fullWidth>
          <Paper>
            <Datagrid
              empty={
                <Box sx={{ p: 2 }}>
                  <Typography variant="body2">{t('resources.partner_groups.empty')}</Typography>
                </Box>
              }
              bulkActionButtons={false}
              sx={{
                '.RaDatagrid-row': {
                  'th:nth-of-type(1), td:nth-of-type(1)': {
                    width: '100%',
                  },
                  'th:nth-of-type(2)': { textAlign: 'center' },
                },
              }}
            >
              <TextField source="name" fullWidth />
              <FunctionField
                label="custom.labels.actions"
                render={(record: RaRecord) => (
                  <Box display="flex">
                    <EditSubItem />
                    <DeleteWithConfirmButton mutationMode="optimistic" redirect={false} />
                  </Box>
                )}
              />
            </Datagrid>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
              <AddSubItem />
            </Box>
          </Paper>
        </ReferenceArrayField>
      </DialogContainer>
    </>
  );
};

export default PartnerGroupField;
