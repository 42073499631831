import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { capitalize } from 'lodash';
import {
  CheckboxGroupInput,
  Edit,
  Labeled,
  ReferenceArrayInput,
  SelectInput,
  TextInput,
  required,
  useGetList,
  useRecordContext,
  useUpdate,
} from 'react-admin';

import SimpleForm from 'components/SimpleForm';
import { memo } from 'react';
import { Designation, Permission } from 'types';

// TODO: How to determine selected?
const RoleSelect = memo(
  ({
    designation,
    part,
    permissions,
  }: {
    designation: string;
    part: string;
    permissions: Permission[] | undefined;
  }) => {
    const record = useRecordContext();

    const r = new RegExp(`${designation} unit ${part}`);
    const p = permissions?.find((permission) => {
      return r.test(permission.name) && record?.permission_ids?.includes(permission.id);
    });
    const m = p?.name.match(/read|write|override/g);
    const getId = (name: string) => {
      return permissions?.find((permission) => {
        return r.test(permission.name) && permission.name.includes(name);
      })?.id;
    };
    const defaultValue = m ? getId(m[0]) : 0;
    const permissionKeys = [
      { id: getId('read'), name: 'read' },
      { id: getId('override'), name: 'override' },
      { id: getId('write'), name: 'write' },
      { id: 0, name: 'none' },
    ];

    return (
      <SelectInput
        label={false}
        source={`permission_ids[${designation} unit ${part}]`}
        choices={permissionKeys}
        defaultValue={defaultValue}
        validate={required()}
      />
    );
  },
);

export const RoleEdit = () => {
  const parts = ['apps', 'locale', 'flavour', 'designation', 'build', 'serial', 'hostname'];

  const { data: designations, isLoading: designationsLoading } = useGetList<Designation>('designations');
  const { data: permissions, isLoading: permissionsLoading } = useGetList<Permission>('permissions');
  const [update] = useUpdate();

  const roleSave = (data: any) => {
    if (!designations || !permissions) return;
    const permission_ids = [];

    for (const key in data.permission_ids) {
      data.permission_ids[key] && permission_ids.push(data.permission_ids[key]);
    }

    data.permission_ids = permission_ids;

    update(
      'roles',
      { id: data.id, data },
      {
        onSuccess: () => {
          //go to the view
          window.location.href = '/#/roles';
        },
      },
    );
  };
  if (designationsLoading || permissionsLoading) return <CircularProgress />;

  return (
    <Edit>
      <SimpleForm onSubmit={roleSave} sanitizeEmptyValues>
        <TextInput source="name" />
        <TextInput source="short_description" />
        <TextInput source="description" sx={{ width: '50%' }} />
        {/* FIXME: getToverviewPermissionsList */}
        {/* TODO: fix filter or get by new property: permission_ids --> toverview_permission_ids */}
        <ReferenceArrayInput source="permission_ids" reference="permissions/alone">
          <CheckboxGroupInput
            row={false}
            label="Toverview permissions"
            optionText={(record) => `resources.permissions.values.${record.name}`}
            translateChoice={true}
          />
        </ReferenceArrayInput>

        {/* FIXME: getGeneratedPermissionsList */}
        {/* TODO: new property? generated_permission_ids */}
        <Labeled label="Generated permissions">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {parts.map((part, index) => (
                    <TableCell key={`head-${part}-${index}`}>{capitalize(part)}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {designations?.map((designation, index) => (
                  <TableRow key={`body-${designation}-${index}`}>
                    <TableCell>{capitalize(designation.name)}</TableCell>
                    {parts.map((part, index) => (
                      <TableCell key={`body-${part}-${index}`}>
                        <RoleSelect
                          designation={designation.name.toLocaleLowerCase()}
                          part={part}
                          permissions={permissions}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
