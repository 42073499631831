import { FC } from 'react';
import {
  Datagrid,
  ReferenceArrayField,
  ReferenceField,
  Show,
  ShowButton,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

export const HardwareRevisionShow: FC<ShowProps> = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <ReferenceField source="hardware_id" reference="hardware" emptyText="-" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="update_channel_id" reference="update_channels" emptyText="-" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField source="update_channel_ids" reference="update_channels" emptyText="-">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <ShowButton />
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
