import { get } from 'lodash';
import { useRecordContext } from 'react-admin';

interface BinarySizeFieldProps {
  source: string;
  emptyText?: string;
  isBinary?: boolean;
}

export const BinarySizeField = (props: BinarySizeFieldProps) => {
  const { source, emptyText = null, isBinary = true } = props;
  const record = useRecordContext();

  const value = parseInt(get(record, source, 0));

  if (value == null || isNaN(value)) {
    return emptyText ? <span>{emptyText}</span> : null;
  }

  const formatSize = (size: number, exp: number, unit: string) => {
    return `${(size / Math.pow(multiplier, exp)).toFixed()} ${unit}`;
  };

  const multiplier = isBinary ? 1024 : 1000;
  let sizeUnit = 'B';
  let exp = 0;

  if (value >= Math.pow(multiplier, 4)) {
    sizeUnit = 'TB';
    exp = 4;
  } else if (value >= Math.pow(multiplier, 3)) {
    sizeUnit = 'GB';
    exp = 3;
  } else if (value >= Math.pow(multiplier, 2)) {
    sizeUnit = 'MB';
    exp = 2;
  } else if (value >= Math.pow(multiplier, 1)) {
    sizeUnit = 'KB';
    exp = 1;
  }

  return <span>{formatSize(value, exp, sizeUnit)}</span>;
};

BinarySizeField.displayName = 'BinarySizeField';
