import { useCustomTabbedForm } from 'components/TabbedForm';
import { useEffect } from 'react';
import { FormTabProps, useFormGroups } from 'react-admin';
// import { useFormState } from 'react-hook-form';

export function useTabbedFormTabHandler(props: FormTabProps) {
  const { hidden } = props;
  const tabName = props.value?.toString() ?? '';
  const formGroups = useFormGroups();
  const formContext = useCustomTabbedForm();

  // const { isDirty, touchedFields } = useFormState();

  useEffect(() => {
    if (!hidden) {
      formContext.setGroupFields(formGroups.getGroupFields(tabName));
    }
  }, [hidden, formGroups, tabName, formContext]);
}
