import { FC } from 'react';
import { stringify } from 'qs';
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  FieldProps,
  ImageField,
  Link,
  List,
  ListProps,
  NumberField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Chip } from '@mui/material';
import FlavourChipField from 'resources/flavours/FlavourChipField';

const CollectionListFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="flavour_id" reference="flavours">
    <SelectInput source="name" optionText={(option) => option.name} emptyText="All" />
  </ReferenceInput>,
  <ReferenceArrayInput source="designation_ids" reference="designations">
    <AutocompleteArrayInput source="name" optionText={(option) => option.name} />
  </ReferenceArrayInput>,
  <BooleanInput source="unlockable" />,
  <BooleanInput source="available_as_subscription" />,
];

// https://marmelab.com/react-admin-demo/#/segments -> Customers button
const LinkToRelatedUnits: FC<FieldProps> = () => {
  const translate = useTranslate();
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Link
      to={{
        pathname: '/units',
        search: stringify({
          filter: JSON.stringify({ collections: record.id }),
        }),
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <Chip
        label={translate('resources.units.count', {
          smart_count: record.units_count,
        })}
        clickable
      />
    </Link>
  );
};

export const CollectionList: FC<ListProps> = () => (
  <List perPage={25} filters={CollectionListFilters}>
    <Datagrid
      rowClick="show"
      sx={{
        '& .RaDatagrid-headerCell': {
          'whiteSpace': 'nowrap',
          '&:nth-of-type(2)': { width: 60 },
        },
      }}
      bulkActionButtons={false}
    >
      <ImageField
        label="custom.labels.icon"
        source="icon.src"
        sx={{
          '& img': { width: '60px !important', height: '60px !important' },
        }}
        emptyText="-"
        sortable={false}
      />
      <TextField source="name" />
      <NumberField source="apps_count" />
      <ReferenceField source="flavour_id" reference="flavours" emptyText="-">
        <FlavourChipField />
      </ReferenceField>
      <BooleanField source="unlockable" />
      <LinkToRelatedUnits label="custom.labels.used_by" />
    </Datagrid>
  </List>
);
