import { FolderSpecial as CollectionIcon } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { SubMenu } from 'constants/SubMenu';

import { CollectionCreate } from './CollectionCreate';
import { CollectionEdit } from './CollectionEdit';
import { CollectionList } from './CollectionList';
import { CollectionShow } from './CollectionShow';

const CollectionResource: ResourceProps = {
  name: 'collections',
  icon: CollectionIcon,
  list: CollectionList,
  show: CollectionShow,
  edit: CollectionEdit,
  create: CollectionCreate,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_collections'),
    menu: SubMenu.AppsAndCollections,
  },

  recordRepresentation: (record) => {
    return record.name;
  },
};

export default CollectionResource;
