import { Datagrid, List, SearchInput, TextField } from 'react-admin';

const RoleListFilters = [<SearchInput source="q" alwaysOn />];

const RoleList = () => (
  <List perPage={25} filters={RoleListFilters} actions={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="short_description" emptyText="-" />
    </Datagrid>
  </List>
);

export default RoleList;
