import * as React from 'react';
import { SvgIcon } from '@mui/material';

const FlavourIcon = React.forwardRef((props: any, ref) => {
  return (
    <SvgIcon {...props} ref={ref}>
      <path
        id="circle3298"
        d="M 12 0 A 12 12 0 0 0 0 12 A 12 12 0 0 0 12 24 A 12 12 0 0 0 24 12 A 12 12 0 0 0 12 0 z M 16.238281 2.1171875 A 3 3 0 0 1 19.238281 5.1171875 A 3 3 0 0 1 16.238281 8.1171875 A 3 3 0 0 1 13.238281 5.1171875 A 3 3 0 0 1 16.238281 2.1171875 z M 9.3730469 10.142578 A 3 3 0 0 1 12.373047 13.142578 A 3 3 0 0 1 9.3730469 16.142578 A 3 3 0 0 1 6.3730469 13.142578 A 3 3 0 0 1 9.3730469 10.142578 z M 12.876953 17.865234 A 3 3 0 0 1 15.876953 20.865234 A 3 3 0 0 1 12.876953 23.865234 A 3 3 0 0 1 9.8769531 20.865234 A 3 3 0 0 1 12.876953 17.865234 z "
      />
    </SvgIcon>
  );
});

export default FlavourIcon;
