import useDialogContext from 'components/DialogContainer/useDialogContext';
import { FC } from 'react';
import { Link, RecordContextProvider, ReferenceInput, SelectInput, TextInput, required } from 'react-admin';

export const HardwareRevisionEditForm: FC = () => {
  const { record } = useDialogContext();
  const { id, rule } = record;
  //@TODO Invoice Id is not available in this record
  return (
    <RecordContextProvider value={record}>
      <TextInput source="name" validate={required()} helperText={false} fullWidth />
      <TextInput source="rule" helperText={false} fullWidth />

      <ReferenceInput source="update_channel_id" reference="update_channels" filter={{ hardware_revision_id: id }}>
        <SelectInput optionText="name" fullWidth />
      </ReferenceInput>
      <Link target="_blank" to={'https://regex101.com/?regex=' + rule}>
        Test at Regex101
      </Link>
    </RecordContextProvider>
  );
};
