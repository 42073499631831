import { get } from 'lodash';
import { useRecordContext } from 'react-admin';

interface SimCardProviderFieldProps {
  source: string;
}
export const SimCardProviderField = (props: SimCardProviderFieldProps) => {
  const { source } = props;
  const record = useRecordContext();

  const value = get(record, source, null);

  if (value == null) {
    return null;
  }

  switch (value) {
    case 'onomondo':
      return <img src="/onomondo.svg" style={{ background: 'black', width: 100, padding: 5 }} alt="Onomondo" />;
    case 'thingsmobile':
      return <img src="/thingsmobile.png" style={{ background: 'black', width: 100, padding: 5 }} alt="ThingsMobile" />;
    default:
      return <span>{value}</span>;
  }
};
SimCardProviderField.displayName = 'SimCardProviderField';
