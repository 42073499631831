import { FC } from 'react';
import { Datagrid, List, ListProps, SearchInput, TextField } from 'react-admin';

const PartnerListFilters = [<SearchInput source="q" alwaysOn />];

export const PartnerList: FC<ListProps> = () => (
  <List perPage={25} filters={PartnerListFilters} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="sales_force_id" emptyText="-" />
      <TextField source="sales_email" emptyText="-" />
      <TextField source="service_email" emptyText="-" />
    </Datagrid>
  </List>
);
