import { Button, ShowButtonProps } from 'react-admin';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

const ShowTabButton = ({ ...props }: ShowButtonProps): ReactElement => {
  const currentPath = useLocation();
  const pathParts = currentPath.pathname.split('/');
  pathParts.splice(pathParts.length - 1, 0, 'show');
  const showLink = pathParts.join('/');

  return <Button label="Show" href={'#' + showLink} />;
};

const EditTabButton = ({ ...props }: ShowButtonProps): ReactElement => {
  const currentPath = useLocation();
  const pathParts = currentPath.pathname.split('/');
  pathParts.splice(pathParts.indexOf('show'), 1);
  const editLink = pathParts.join('/');

  return <Button label="Edit" href={'#' + editLink} />;
};

export { EditTabButton, ShowTabButton };
