import { Grid, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import {
  Datagrid,
  EditButton,
  ReferenceArrayField,
  ReferenceField,
  ShowBase,
  ShowButton,
  ShowProps,
  SimpleShowLayout,
  TextField,
  Title,
  useRecordContext,
  useShowContext,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';

export const HardwareShow: FC<ShowProps> = () => {
  return (
    <ShowBase>
      <HardwareShowView />
    </ShowBase>
  );
};

const HardwareShowView = (props: any) => {
  const translate = useTranslate();
  const { title } = props;
  const { resource, defaultTitle } = useShowContext(props);
  const record = useRecordContext();

  return (
    record && (
      <>
        <Title title={title} defaultTitle={defaultTitle} preferenceKey={`${resource}.show.title`} />
        <Stack>
          <Grid container flexDirection="row">
            <Grid container spacing={4} justifyContent="flex-end" sx={{ mt: 4 }}>
              <EditButton />
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {translate('resources.hardware.section.general')}
                </Typography>
                <Paper>
                  <SimpleShowLayout sx={{ p: 2 }}>
                    <TextField source="name" />
                    <ReferenceField source="flavour_id" reference="flavours">
                      <TextField source="name" />
                    </ReferenceField>
                    <TextField source="serial_validation_rule" />
                    <Link target="_blank" to={'https://regex101.com/?regex=' + record?.serial_validation_rule}>
                      Test at Regex101
                    </Link>
                  </SimpleShowLayout>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {translate('resources.hardware.section.hardware_revisions')}
                </Typography>
                <Paper>
                  <SimpleShowLayout sx={{ p: 2 }}>
                    <ReferenceArrayField reference="hardware_revisions" source="hardware_revision_ids">
                      <Datagrid bulkActionButtons={false}>
                        <TextField source="name" />
                        <ShowButton />
                      </Datagrid>
                    </ReferenceArrayField>
                  </SimpleShowLayout>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {translate('resources.hardware.section.software_components')}
                </Typography>
                <Paper>
                  <SimpleShowLayout sx={{ p: 2 }}>
                    <ReferenceArrayField source="software_component_ids" reference="software_components">
                      <Datagrid bulkActionButtons={false}>
                        <TextField source="name" />
                      </Datagrid>
                    </ReferenceArrayField>
                  </SimpleShowLayout>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </>
    )
  );
};
