import { FolderZip } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';
import { SoftwareReleaseCreate } from './SoftwareReleaseCreate';
import { SoftwareReleaseEdit } from './SoftwareReleaseEdit';
import { SoftwareReleaseList } from './SoftwareReleaseList';
import { SoftwareReleaseShow } from './SoftwareReleaseShow';
import { SubMenu } from '../../constants/SubMenu';

const SoftwareReleaseResource: ResourceProps = {
  name: 'software_releases',
  options: {
    isVisible: (hasPermission: Function) => hasPermission('dev_options'),
    menu: SubMenu.Develop,
  },
  icon: FolderZip,
  list: SoftwareReleaseList,
  show: SoftwareReleaseShow,
  edit: SoftwareReleaseEdit,
  create: SoftwareReleaseCreate,
  recordRepresentation: (record) => {
    return record.name;
  },
};

export default SoftwareReleaseResource;
