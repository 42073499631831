import { Grid, Typography } from '@mui/material';
import {
  FunctionField,
  Labeled,
  Link,
  RaRecord,
  ReferenceField,
  Tab,
  TabProps,
  TextField,
  useTranslate,
} from 'react-admin';

import { FormattedDateField } from 'components/FormattedDateField';
import SFIcon from 'components/SFIcon';
import OnlineStatusField from '../../OnlineStatusField';

export const InfoTab = (props: Omit<TabProps, 'children'>) => {
  const translate = useTranslate();

  return (
    <Tab {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={10}>
          <Typography variant="h6" gutterBottom>
            {translate('resources.units.section.general')}
          </Typography>
          <Grid container item spacing={2} columns={{ xs: 1, sm: 4, md: 6 }}>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="serial" fullWidth />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="configuration_key" emptyText="-" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <FunctionField
                  source="sf_id"
                  label="SalesForce ID"
                  render={(record: RaRecord) =>
                    record.sf_id ? (
                      <Link
                        //to={`${process.env.REACT_APP_SF}/sims/${record.mobile_network.sim_id}`}
                        to={`https://tover.lightning.force.com/lightning/r/Configuration__c/${record.sf_id}/view`}
                        target="_blank"
                      >
                        <SFIcon sx={{ position: 'relative', top: 8, marginRight: 1 }} />
                        {record.sf_id}
                      </Link>
                    ) : (
                      ''
                    )
                  }
                />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <ReferenceField source="hardware_id" reference="hardware">
                  <TextField source="name" emptyText="-" />
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <ReferenceField source="hardware_revision_id" reference="hardware_revisions">
                  <TextField source="name" emptyText="-" />
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="hostname" emptyText="-" />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={10}>
          <Typography variant="h6" gutterBottom>
            {translate('resources.units.section.details')}
          </Typography>
          <Grid container item spacing={2} columns={{ xs: 1, sm: 4, md: 6 }}>
            <Grid item xs={1}>
              <Labeled>
                <OnlineStatusField source="online_status" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="software_versions.tover_tafel" emptyText="-" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="software_versions.tover_firmware" emptyText="-" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="software_versions.tover_up" emptyText="-" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="software_versions.tover_tele" emptyText="-" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <TextField source="software_versions.tover_sysup" emptyText="-" />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Typography variant="h6" gutterBottom>
            {translate('resources.units.section.history')}
          </Typography>
          <Grid container item spacing={2} columns={{ xs: 1, sm: 4, md: 6 }}>
            <Grid item xs={1}>
              <Labeled>
                <FormattedDateField source="install_date" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <FormattedDateField source="last_online" />
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <FormattedDateField source="last_edit" />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Typography variant="h6" gutterBottom>
            {translate('resources.units.section.partner')}
          </Typography>
          <Grid container item spacing={2} columns={{ xs: 1, sm: 4, md: 6 }}>
            <Grid item xs={1}>
              <Labeled>
                <ReferenceField source="partner_id" reference="partners">
                  <TextField source="name" />
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item xs={1}>
              <Labeled>
                <ReferenceField source="partner_group_id" reference="partner_groups" emptyText="-">
                  <TextField source="name" />
                </ReferenceField>
              </Labeled>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Tab>
  );
};

InfoTab.defaultProps = {
  label: 'resources.units.tabs.info',
  value: 'info',
  path: 'info',
};
