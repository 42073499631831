import { Grid } from '@mui/material';

import Protect from 'components/Protect';
import { FormTab, FormTabProps } from 'react-admin';
import { useTabbedFormTabHandler } from '../useTabbedFormTabHandler';
import { AppCollectionsField } from './AppCollectionsField';
import { PurchasedAppsField } from './PurchasedAppsField';
import { SubscriptionField } from './SubscriptionField';
import { TrialAppsField } from './TrialAppsField';
import { TrialCollectionsField } from './TrialCollectionsField';

export const UnitEditApps = (props: FormTabProps) => {
  useTabbedFormTabHandler(props);

  return (
    <Protect hasAny={['manage_units', /unit apps write/]} permissions="manage_units">
      <FormTab {...props}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container direction="column">
            <SubscriptionField />
            <AppCollectionsField />
            <PurchasedAppsField />
            <TrialAppsField />
            <TrialCollectionsField />
          </Grid>
        </Grid>
      </FormTab>
    </Protect>
  );
};

UnitEditApps.defaultProps = {
  label: 'resources.units.tabs.apps',
  value: 'apps',
  path: 'apps',
};
