import {
  BooleanField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';

import AddSubItem from 'components/SimpleForm/AddSubtem';
import EditSubItem from 'components/SimpleForm/EditSubItem';
import DialogContainer from 'components/DialogContainer';
import { SubscriptionCreateForm } from './SubscriptionCreateForm';
import { SubscriptionEditForm } from './SubscriptionEditForm';

export const SubscriptionField = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <Grid item>
      <Typography variant="h6">{translate('resources.units.section.subscriptions')}</Typography>
      <DialogContainer
        createForm={<SubscriptionCreateForm record={record} />}
        editForm={<SubscriptionEditForm />}
        resource="app_collection_subscriptions"
      >
        <ReferenceArrayField source="collection_subscription_ids" reference="app_collection_subscriptions">
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="app_collection_id" reference="collections">
              <TextField source="name" />
            </ReferenceField>

            <BooleanField source="active" />
            <BooleanField source="my_tover_access" />
            <DateField source="valid_from" />
            <DateField source="activated_at" />
            <DateField source="valid_until" />
            <TextField source="unlock_key" />
            <Box display="flex" justifyContent="center" p={2}>
              <EditSubItem />
              <DeleteWithConfirmButton mutationMode="optimistic" redirect={false} />
            </Box>
          </Datagrid>
          <Box display="flex" justifyContent="center" p={2}>
            <AddSubItem />
          </Box>
        </ReferenceArrayField>
      </DialogContainer>
    </Grid>
  );
};
