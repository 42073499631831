import { Tab, TabProps } from 'react-admin';

import { DiagnosticsList } from 'resources/diagnostics/components/DiagnosticsList';

export const DiagnosticsTab = (props: Omit<TabProps, 'children'>) => {
  return (
    <Tab {...props}>
      <DiagnosticsList />
    </Tab>
  );
};

DiagnosticsTab.defaultProps = {
  label: 'resources.units.tabs.diagnostics',
  value: 'diagnostics',
  path: 'diagnostics',
};
