import { defaultTheme, RaThemeOptions } from 'react-admin';
import { deepmerge } from '@mui/utils';

// Source Serif Pro
import '@fontsource/source-serif-pro/400.css';
import '@fontsource/source-serif-pro/600.css';
import '@fontsource/source-serif-pro/700.css';

// Source Sans Pro
import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/400-italic.css';
import '@fontsource/source-sans-pro/600.css';
import '@fontsource/source-sans-pro/700.css';

// Note that you don’t need to call createTheme yourself. React-admin will do it for you.

let theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
  },
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#f1be48',
    },
    secondary: {
      main: '#081f2c',
    },
  },
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeightMedium: 600,
    h1: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 700,
      fontSize: '2rem',
      letterSpacing: 0.25,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 700,
      fontSize: '1.75rem',
      letterSpacing: 0.25,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 600,
      fontSize: '1.25rem',
      letterSpacing: 0.15,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 300,
      letterSpacing: 0.15,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '1.125rem',
      fontWeight: 300,
      letterSpacing: 0.2,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1.125rem',
      letterSpacing: 0.25,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.8125rem',
      letterSpacing: 0.25,
      lineHeight: 1.5,
    },
    button: {
      fontSize: '1.125rem',
      fontWeight: 600,
      textTransform: 'none',
      lineHeight: 1.2,
    },
    caption: {
      fontSize: '0.8125rem',
      opacity: 0.6,
      letterSpacing: 0.4,
      lineHeight: 1.2,
    },
    overline: {
      fontSize: '0.688rem',
      opacity: 0.6,
      textTransform: 'uppercase',
      letterSpacing: 1,
      lineHeight: 1.2,
    },
  },
};

theme = deepmerge(theme, {
  components: {
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          'borderRadius': 30,
          'borderTopRightRadius': 10,
          'borderWidth': 2,
          'padding': '4px 16px',
          '&:hover': {
            borderWidth: 2,
          },
        },
        contained: {
          'border': '2px solid',
          '&.Mui-disabled': {
            borderColor: 'inherit',
          },
        },
        containedPrimary: {
          'borderColor': theme.palette.primary.main,
          '&:hover': {
            backgroundColor: 'white',
            borderColor: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          'color': theme.palette.secondary.main,
          'fontWeight': 'bold',
          'textDecoration': 'underline',
          'textUnderlinePosition': 'under',
          '&:hover': {
            textDecorationThickness: 2,
          },
        },
      },
    },
    RaButton: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
        },
      },
    },
    RaSingleFieldList: {
      styleOverrides: {
        root: {
          '& .RaSingleFieldList-link': {
            '& > *': {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
    },
    RaReferenceField: {
      styleOverrides: {
        root: {
          '& .RaReferenceField-link': {
            '& > *': {
              'color': theme.palette.secondary.main,
              'fontWeight': 'bold',
              'textDecoration': 'underline',
              'textUnderlinePosition': 'under',
              '&:hover': {
                textDecorationThickness: 2,
              },
            },
          },
        },
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-expandedPanel': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    RaSaveButton: {
      styleOverrides: {
        root: {
          'borderColor': theme.palette.primary.main,
          'backgroundColor': theme.palette.primary.main,
          'fontSize': theme.typography.body2.fontSize,
          'lineHeight': 1.5,
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

export default theme as RaThemeOptions;
