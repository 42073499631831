import { FC } from 'react';
import {
  BooleanField,
  Datagrid,
  ImageField,
  ReferenceArrayField,
  Show,
  ShowButton,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import AppChipField from 'resources/apps/AppChipField';

export const DesignationShow: FC<ShowProps> = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="resources.designations.tabs.general">
        <TextField source="name" />
        <BooleanField source="default" />
      </Tab>
      <Tab label="resources.designations.tabs.collections">
        <ReferenceArrayField source="app_collection_ids" reference="collections" label={false}>
          <Datagrid bulkActionButtons={false}>
            <ImageField source="icon.src" label="custom.labels.icon" />
            <TextField source="name" emptyText="-" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
      <Tab label="resources.designations.tabs.apps">
        <ReferenceArrayField source="app_ids" reference="apps" emptyText="-">
          <SingleFieldList linkType="show">
            <AppChipField />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
