import { Typography } from '@mui/material';
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  ReferenceInput,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

interface AddSoftwareReleaseFormProps {
  record?: any;
  resource?: string;
}

const ReleaseInputs = () => {
  const releaseSelected = useWatch({ name: 'software_release_id' });
  const releaseNow = useWatch({ name: 'release_now' });

  return (
    releaseSelected && (
      <>
        <DateTimeInput source="released_at" fullWidth disabled={releaseNow} />
        <BooleanInput source="release_now" />
      </>
    )
  );
};

export const AddSoftwareReleaseForm = (props: AddSoftwareReleaseFormProps) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  return (
    <>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {translate('resources.channel_releases.forms.create.header')}
      </Typography>
      <ReferenceInput
        reference="software_releases"
        source="software_release_id"
        filter={{ hardware_revision_id: record.hardware_revision_id }}
      >
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
      <ReleaseInputs />
      <TextInput source="update_channel_id" type="hidden" defaultValue={record?.id} sx={{ display: 'none' }} />
    </>
  );
};
