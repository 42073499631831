import { FC, forwardRef } from 'react';
import { Logout, UserMenu, UserMenuProps } from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';

import { useProfile } from 'resources/account/AccountEdit';

const ConfigurationMenu = forwardRef((props, ref) => {
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/account"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>Account settings</ListItemText>
    </MenuItem>
  );
});

const CustomUserMenu: FC<UserMenuProps> = (props) => {
  const { profileVersion } = useProfile();

  return (
    <UserMenu key={profileVersion} {...props}>
      <ConfigurationMenu />
      <Logout />
    </UserMenu>
  );
};

export default CustomUserMenu;
