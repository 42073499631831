import { FieldValues, SubmitHandler, UseFormProps } from 'react-hook-form';

import { BaseSyntheticEvent, useCallback, useMemo, useRef } from 'react';
import {
  RaRecord,
  ValidateForm,
  getSimpleValidationResolver,
  useNotifyIsFormInvalid,
  useRecordContext,
  useSaveContext,
  useWarnWhenUnsavedChanges,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import getFormInitialValues from './getFormInitialValues';
import { sanitizeEmptyValues as sanitizeValues } from './sanitizeEmptyValues';
import { setSubmissionErrors } from './setSubmissionErrors';

export type UseAugmentedFormProps = UseFormOwnProps &
  Omit<UseFormProps, 'onSubmit'> & {
    validate?: ValidateForm;
  };

export interface UseFormOwnProps {
  defaultValues?: any;
  formRootPathname?: string;
  record?: Partial<RaRecord>;
  onSubmit?: SubmitHandler<FieldValues>;
  warnWhenUnsavedChanges?: boolean;
  sanitizeEmptyValues?: boolean;
}

/**
 * Wrapper around react-hook-form's useForm
 *
 * This hook adds the following features to react-hook-form's useForm:
 *
 * - form initialization based on RecordContext
 * - validation based on a validate function
 * - sanitization of empty values
 * - notification on invalid form
 * - stop form submission event propagation
 */
export const useAugmentedForm = (props: UseAugmentedFormProps) => {
  const {
    criteriaMode = 'firstError',
    defaultValues,
    formRootPathname,
    resolver,
    reValidateMode = 'onChange',
    onSubmit,
    sanitizeEmptyValues,
    warnWhenUnsavedChanges,
    validate,
    ...rest
  } = props;
  const record = useRecordContext(props);
  const saveContext = useSaveContext();

  const defaultValuesIncludingRecord = useMemo(
    () => getFormInitialValues(defaultValues, record),
    // eslint-disable-next-line
    [
      // eslint-disable-next-line
      JSON.stringify({
        defaultValues: typeof defaultValues === 'function' ? 'function' : defaultValues,
        record,
      }),
    ],
  );

  const finalResolver = resolver ? resolver : validate ? getSimpleValidationResolver(validate) : undefined;

  const form = useForm({
    criteriaMode,
    values: defaultValuesIncludingRecord,
    reValidateMode,
    resolver: finalResolver,
    ...rest,
  });

  const formRef = useRef(form);

  // notify on invalid form
  useNotifyIsFormInvalid(form.control);

  // warn when unsaved change
  useWarnWhenUnsavedChanges(Boolean(warnWhenUnsavedChanges), formRootPathname, form.control);

  // submit callbacks
  const handleSubmit = useCallback(
    async (values: any, record: any = {}) => {
      let errors;
      const finalValues = sanitizeEmptyValues ? sanitizeValues(values, record) : values;
      if (onSubmit) {
        //@ts-ignore
        errors = await onSubmit(finalValues);
      }
      if (onSubmit == null && saveContext?.save) {
        //@ts-ignore
        errors = await saveContext.save(finalValues);
      }
      if (errors != null) {
        setSubmissionErrors(errors, formRef.current.setError);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmit, saveContext, sanitizeEmptyValues, record],
  );

  const formHandleSubmit = useCallback(
    (event: BaseSyntheticEvent) => {
      if (!event.defaultPrevented) {
        // Prevent outer forms to receive the event
        event.stopPropagation();
        form.handleSubmit(handleSubmit)(event);
      }
      return;
    },
    [form, handleSubmit],
  );

  return {
    form,
    handleSubmit,
    formHandleSubmit,
  };
};
