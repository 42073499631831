import React, { FC } from 'react';
import { ChipFieldProps, useRecordContext } from 'react-admin';

import { Tooltip } from '@mui/material';
import FlavourIcon from './icons/FlavourIcon';

const FlavourChipField: FC<ChipFieldProps> = ({ source = 'name', ...props }) => {
  const record = useRecordContext();

  // TODO: make clickable conditional (parent should pass a link; not accessible)
  return (
    <Tooltip title={record.name}>
      <FlavourIcon
        color={record.color}
        sx={{ width: 24, height: 24, mr: '0.4rem', color: `${record.color} !important` }}
      />
    </Tooltip>
  );
};

export default FlavourChipField;
