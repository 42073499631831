import {
  Datagrid,
  DateField,
  List,
  ListProps,
  SearchInput,
  SelectInput,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { FC } from 'react';
import { MorphField } from '../../components/Fields/MorphField';

const PropertiesPanel = () => {
  const record = useRecordContext();
  return (
    <>
      <table>
        {Object.keys(record.properties.old || record.properties.attributes)
          .sort()
          .map((key) => (
            <tr>
              <td>
                <strong>{key}:</strong>
              </td>
              <td>
                {record.properties.old && record.properties.old[key] ? JSON.stringify(record.properties.old[key]) : ''}
              </td>
              <td>=&gt;</td>
              <td>{JSON.stringify(record.properties.attributes[key])}</td>
            </tr>
          ))}
      </table>
    </>
  );
};

const SubjectInput: FC<{ source: string }> = ({ source }) => {
  const { data, isLoading } = useGetList('activity_logs/subjects');
  return <SelectInput source={source} choices={data} optionText="id" isLoading={isLoading} />;
};

const ActivityLogFilter = [
  <SearchInput source="q" alwaysOn />,
  <SelectInput source="description" optionText="id" choices={[{ id: 'updated' }, { id: 'created' }]} />,
  <SubjectInput source="subject_type" />,
];

const ActivityLogList: FC<ListProps> = () => (
  <List perPage={25} filters={ActivityLogFilter} sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false} expand={<PropertiesPanel />} isRowExpandable={(row) => row.properties}>
      <DateField showTime source="created_at" />
      <TextField source="subject_type" />
      <TextField source="description" />
      <MorphField morph="subject" label="Subject" />
      <MorphField morph="related_object" label="Related" />
      <MorphField morph="causer" label="Causer" />
    </Datagrid>
  </List>
);

export default ActivityLogList;
