import React, { ReactElement, useState } from 'react';
import { useResourceContext, useGetResourceLabel, useRefresh, RaRecord } from 'react-admin';
import DialogContext from './DialogContext';

interface DialogContextProviderProps {
  children: React.ReactNode;
  createForm?: ReactElement;
  editForm?: ReactElement;
  resource?: string;
}
const DialogContextProvider = (props: DialogContextProviderProps) => {
  const { children, createForm, editForm } = props;
  const resource = useResourceContext(props);
  const getResourceLabel = useGetResourceLabel();
  const [record, setRecord] = useState<RaRecord | undefined>();
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const refresh = useRefresh();

  const openCreateDialog = () => setIsCreating(true);
  const openEditDialog = (record: RaRecord) => {
    setIsEditing(true);
    setRecord(record);
  };
  const closeDialog = () => {
    refresh();
    setIsCreating(false);
    setIsEditing(false);
    setRecord(undefined);
  };

  return (
    <DialogContext.Provider
      value={{
        isEditing,
        isCreating,
        record,
        createForm,
        editForm,
        resource,
        setRecord,
        openCreateDialog,
        openEditDialog,
        closeDialog,
        label: getResourceLabel(resource),
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export default DialogContextProvider;
