import { AdminPanelSettings as RoleIcon } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import * as t from 'types';
import { RoleEdit } from './RoleEdit';
import RoleList from './RoleList';
import RoleShow from './RoleShow';
import { SubMenu } from '../../constants/SubMenu';

const pFn = (hasPermission: Function) => hasPermission('manage_userroles');

const RoleResource: ResourceProps = {
  name: 'roles',
  icon: RoleIcon,
  list: RoleList,
  show: RoleShow,
  edit: RoleEdit,
  recordRepresentation: (record: t.Role) => record.short_description || record.name,
  options: {
    isVisible: pFn,
    canEdit: pFn,
    canList: pFn,
    canShow: pFn,
    canCreate: pFn,
    menu: SubMenu.Configuration,
  },
};

export default RoleResource;
