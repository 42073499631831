import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { RSH } from 'components';

const RSHDialog = ({
  open,
  handleClose,
  record,
}: {
  open: boolean;
  handleClose: (_event: object, reason?: string) => void;
  record: any;
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullScreen disableEscapeKeyDown sx={{ margin: '5%' }}>
      <DialogTitle>Remote Shell Terminal: {record.serial}</DialogTitle>
      <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
        <Close />
      </IconButton>
      <DialogContent>
        <RSH serial={record?.serial} />
      </DialogContent>
    </Dialog>
  );
};

export default RSHDialog;
