import {
  Datagrid,
  DateField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  ShowButton,
  TextField,
  useRecordContext,
} from 'react-admin';

export const AppPurchasesField = (props: any = { label: 'Apps', source: 'app_ids', reference: 'apps' }) => {
  const record = useRecordContext();

  return (
    <ReferenceArrayField
      source={props.source}
      reference={props.reference}
      label={props.label}
      emptyText="-"
      record={record}
    >
      <Datagrid size="medium" bulkActionButtons={false} empty={<>-</>}>
        <ImageField
          label={false}
          source="icon.src"
          sx={{
            '& img': { width: '40px !important', height: '40px !important' },
          }}
          emptyText=""
        />

        <TextField source="translated_name" />
        <DateField source="created_at" />
        <ReferenceField reference="apps" source="app_id" link="show">
          <ShowButton />
        </ReferenceField>
      </Datagrid>
    </ReferenceArrayField>
  );
};
