import { Grid } from '@mui/material';
import Protect from 'components/Protect';
import { get } from 'lodash';
import { BooleanInput, FormTab, FormTabProps, useRecordContext } from 'react-admin';
import { useTabbedFormTabHandler } from '../useTabbedFormTabHandler';

export const UnitEditDev = (props: FormTabProps) => {
  const record = useRecordContext();
  const is_lte_enabled = get(record, 'developer_options.is_lte_enabled');
  useTabbedFormTabHandler(props);

  return (
    <Protect permissions={['dev_options', 'manage_units']}>
      <FormTab {...props}>
        <Grid container direction="column">
          <Grid item>
            <BooleanInput source="developer_options.no_getconfig" />
          </Grid>
          <Grid item>
            <BooleanInput source="developer_options.debug_panel" />
          </Grid>
          <Grid item>
            <BooleanInput source="developer_options.diagnostics" />
          </Grid>
          <Grid item>
            <BooleanInput source="developer_options.design_tools" />
          </Grid>
          <Grid item>
            <BooleanInput source="developer_options.staging_api" />
          </Grid>
          {is_lte_enabled !== null && (
            <Grid item>
              <BooleanInput source="developer_options.is_lte_enabled" />
            </Grid>
          )}
        </Grid>
      </FormTab>
    </Protect>
  );
};

UnitEditDev.propTypes = FormTab.propTypes;

UnitEditDev.defaultProps = {
  label: 'resources.units.tabs.developer_options',
  value: 'developer_options',
  path: 'developer_options',
};
