import { PlaylistPlay } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { PlaylistList } from './PlaylistList';
import { PlaylistShow } from './PlaylistShow';
import { SubMenu } from '../../constants/SubMenu';

const PlaylistResource: ResourceProps = {
  name: 'playlists',
  icon: PlaylistPlay,
  list: PlaylistList,
  show: PlaylistShow,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_collections'),
    menu: SubMenu.AppsAndCollections,
  },

  recordRepresentation: (record) => {
    return record.key;
  },
};

export default PlaylistResource;
