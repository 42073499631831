import { ReferenceField, TextField, useRecordContext } from 'react-admin';

// @ts-ignore
export const MorphField = ({ morph, label }) => {
  const record = useRecordContext();

  const parseSubject = (morph: string) => {
    if (!morph) return;
    //if subject contains Configuration
    if (morph === 'configuration' || morph.includes('Configuration')) {
      return {
        morphSource: 'id',
        morphReference: 'configurations',
      };
    }
    if (morph === 'unit' || morph.includes('UnitModel')) {
      return {
        morphSource: 'serial',
        morphReference: 'units',
      };
    }
    if (morph === 'user' || morph.includes('User')) {
      return {
        morphSource: 'name',
        morphReference: 'users',
      };
    }

    if (morph === 'subscription' || morph.includes('Subscription')) {
      return {
        morphSource: 'name',
        morphReference: 'subscriptions',
      };
    }
    //return 'configurations'
    //if subject contains Unit
  };
  const subject = parseSubject(record[morph + '_type']);
  return (
    subject && (
      <ReferenceField source={morph + '_id'} reference={subject!.morphReference}>
        <TextField source={subject!.morphSource} label={label || morph} />
      </ReferenceField>
    )
  );
};
