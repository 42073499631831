import { ResourceProps } from 'react-admin';

import * as t from 'types';

const RegionResource: ResourceProps = {
  name: 'regions',
  recordRepresentation: (record: t.Region) => record.name,
};

export default RegionResource;
