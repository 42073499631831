import { Grid, Typography, Paper, Box } from '@mui/material';
import DialogContainer from 'components/DialogContainer';
import AddSubItem from 'components/SimpleForm/AddSubtem';
import EditSubItem from 'components/SimpleForm/EditSubItem';
import {
  ReferenceArrayField,
  useRecordContext,
  Datagrid,
  TextField,
  DeleteWithConfirmButton,
  RaRecord,
} from 'react-admin';
import { RegionCreateForm } from './RegionCreateForm';

interface RegionFieldProps {
  record?: RaRecord;
}

const RegionField = (props: RegionFieldProps) => {
  const record = useRecordContext(props);

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Regions
      </Typography>
      <DialogContainer createForm={<RegionCreateForm record={record} />} resource="regions">
        <ReferenceArrayField resource="regions" reference="regions" source="region_ids" fullWidth>
          <Paper>
            <Datagrid
              empty={
                <Box sx={{ p: 2 }}>
                  <Typography variant="body2">There are no regions to this country.</Typography>
                </Box>
              }
              bulkActionButtons={false}
              sx={{
                '.RaDatagrid-row': {
                  'th:nth-of-type(1), td:nth-of-type(1)': {
                    width: '100%',
                  },
                  'th:nth-of-type(2)': { textAlign: 'center' },
                },
              }}
            >
              <TextField source="name" />
              <Box display="flex" justifyContent="center" p={2}>
                <EditSubItem />
                <DeleteWithConfirmButton mutationMode="optimistic" redirect={false} />
              </Box>
            </Datagrid>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
              <AddSubItem />
            </Box>
          </Paper>
        </ReferenceArrayField>
      </DialogContainer>
    </Grid>
  );
};

export default RegionField;
