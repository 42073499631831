import { ResourceProps } from 'react-admin';
import { Public as CountryIcon } from '@mui/icons-material';

import { SubMenu } from 'constants/SubMenu';
import * as t from 'types';

import { CountryList } from './CountryList';
import { CountryShow } from './CountryShow';
import { CountryEdit } from './CountryEdit';

const CountryResource: ResourceProps = {
  name: 'countries',
  icon: CountryIcon,
  list: CountryList,
  show: CountryShow,
  edit: CountryEdit,
  recordRepresentation: (record: t.Country) => record.name,
  options: {
    menu: SubMenu.Configuration,
  },
};

export default CountryResource;
