import { Grid, Typography } from '@mui/material';
import {
  AutocompleteArrayInput,
  BooleanInput,
  FormTab,
  FormTabProps,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

import Protect from 'components/Protect';
import { useTabbedFormTabHandler } from '../useTabbedFormTabHandler';

export const UnitEditConfig = (props: FormTabProps) => {
  const translate = useTranslate();
  const hardware_revision_id = useWatch({ name: 'hardware_revision_id' });
  useTabbedFormTabHandler(props);

  return (
    <Protect hasAny={[/(locale|build|designation) write$/]} permissions="manage_units">
      <FormTab {...props}>
        <Grid container direction="column" xs={12} lg={6} xl={4}>
          <Typography variant="h6">{translate('resources.units.section.localization')}</Typography>
          <Grid item>
            <ReferenceInput source="locale_id" reference="locales">
              <Protect permissions={[/locale write$/, 'manage_units']}>
                <SelectInput optionText="name" optionValue="id" fullWidth />
              </Protect>
            </ReferenceInput>
          </Grid>
          <Grid item>
            <ReferenceArrayInput source="language_ids" reference="locales">
              <Protect permissions={[/locale write$/, 'manage_units']}>
                <AutocompleteArrayInput optionText="name" optionValue="id" />
              </Protect>
            </ReferenceArrayInput>
          </Grid>

          <Typography variant="h6">{translate('resources.units.section.preferences')}</Typography>
          <Grid item>
            <ReferenceInput source="designation_id" reference="designations">
              <Protect permissions={[/designation write$/, 'manage_units']}>
                <SelectInput optionText="name" optionValue="id" fullWidth />
              </Protect>
            </ReferenceInput>
          </Grid>
          <Grid item>
            <Protect permissions={[/build write$/, 'manage_units']}>
              <ReferenceInput
                source="update_channel_id"
                reference="update_channels"
                filter={{ hardware_revision_id }}
              />
            </Protect>
          </Grid>

          <Typography variant="h6">{translate('resources.units.section.access_control')}</Typography>
          <Grid item>
            <BooleanInput source="studio_access" />
          </Grid>
        </Grid>
      </FormTab>
    </Protect>
  );
};

UnitEditConfig.defaultProps = {
  label: 'resources.units.tabs.configuration',
  value: 'configuration',
  path: 'configuration',
};
