import { FC } from 'react';
import { Edit, EditProps } from 'react-admin';
import { UpdateChannelPutForm } from './components';

export const UpdateChannelEdit: FC<EditProps> = () => {
  return (
    <Edit>
      <UpdateChannelPutForm />
    </Edit>
  );
};
