import { FieldValues, UseFormSetError } from 'react-hook-form';
import { ValidationErrorMessageWithArgs } from 'react-admin';

export const setSubmissionErrors = (errors: FieldValues, setError: UseFormSetError<FieldValues>) => {
  const setErrorFromObject = (errors: FieldValues, rootPath: string) => {
    Object.entries(errors).forEach(([name, error]) => {
      if (typeof error === 'object' && !(error as ValidationErrorMessageWithArgs)) {
        setErrorFromObject(error, `${rootPath}${name}.`);
        return;
      }
      setError(`${rootPath}${name}`, {
        message: error,
        type: 'server',
      });
    });
  };
  setErrorFromObject(errors, '');
};
