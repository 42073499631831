import { FC } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { FolderSpecial } from '@mui/icons-material';
import { useRecordContext } from 'react-admin';

export const AppCollectionOptionRenderer: FC = () => {
  const record = useRecordContext();

  return (
    <ListItem disablePadding>
      <ListItemAvatar>
        <Avatar src={record.icon?.src} variant="square">
          <FolderSpecial />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={record.name} secondary={record.configuration_id} />
    </ListItem>
  );
};
