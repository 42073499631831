import { FC } from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { AppOptionRenderer } from './AppOptionRenderer';

export const PurchasedAppsField: FC = () => {
  const t = useTranslate();
  return (
    <Grid item mb={2}>
      <Typography variant="h6">{t('resources.units.section.purchased_apps')}</Typography>
      <ReferenceArrayInput source="app_ids" reference="apps">
        <AutocompleteArrayInput
          label={false}
          optionText={<AppOptionRenderer />}
          inputText={(option) => option.translated_name}
          matchSuggestion={(filter, choice) => choice.translated_name.toLowerCase().includes(filter.toLowerCase())}
        />
      </ReferenceArrayInput>
    </Grid>
  );
};
