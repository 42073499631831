import { FC, useEffect, useRef } from 'react';
import { useDataProvider } from 'react-admin';
import { Terminal } from 'xterm';
import { AttachAddon } from 'xterm-addon-attach';
import { FitAddon } from 'xterm-addon-fit';

import 'xterm/css/xterm.css';

interface SocketPayload {
  [key: string]: {
    server: string;
    idconnection?: string;
  };
}

export const RSH: FC<{ serial?: string }> = ({ serial }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const provider = useDataProvider();
  // @ts-ignore
  let socket = useRef<WebSocket | null>(null);
  const terminal = new Terminal();

  let socketId = '';

  const authPayload: SocketPayload = {};

  let connectPayload: SocketPayload = {};

  function setupTerminal(socket: WebSocket) {
    if (!ref.current) return;
    const fitAddon = new FitAddon();
    const attachAddon = new AttachAddon(socket);
    terminal.loadAddon(fitAddon);
    terminal.loadAddon(attachAddon);

    terminal.open(ref.current.parentElement!);
    fitAddon.fit();
  }

  function refreshConnection() {
    setTimeout(() => {
      socket.current?.send(JSON.stringify(connectPayload));
      setInterval(() => {
        socket.current?.send(JSON.stringify({ refresh: connectPayload.connect }));
      }, 700);
    }, 700);
  }

  const matchPayload = (socket: WebSocket) => (message: MessageEvent) => {
    try {
      const { event, data } = JSON.parse(message.data);

      switch (event) {
        case 'pusher:connection_established':
          socketId = JSON.parse(data).socket_id;
          connectPayload.connect.idconnection = socketId;
          console.debug({ event, data, socketId, connectPayload });
          socket?.send(JSON.stringify(connectPayload));

          setupTerminal(socket);

          break;
        case 'pusher:vpn_connection_established':
          message.stopImmediatePropagation();
          message.stopPropagation();

          authPayload.auth.idconnection = connectPayload.connect.idconnection;
          socket?.send(JSON.stringify(authPayload));
          break;
      }
    } catch (e) {
      //console.error(e);
    }
  };

  function transmitData({ key }: { key: string }) {
    const data = { data: { data: key } };

    socket.current?.send(JSON.stringify(data));
  }

  useEffect(() => {
    if (!serial) return;
    if (socket.current !== null) return;

    authPayload.auth = {
      server: serial,
    };

    connectPayload.connect = {
      server: serial,
    };

    provider.create(`unit/${serial}/startTerminal`, { data: {} }).catch(() => null);
    socket.current = new WebSocket(`wss://${process.env.REACT_APP_RSH_DOMAIN}/app/${serial}`);
    socket.current.addEventListener('open', refreshConnection);
    socket.current.addEventListener('message', matchPayload(socket.current));

    terminal.onKey(transmitData);
  });

  return <div ref={ref}></div>;
};
