import { FC } from 'react';
import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  ImageField,
  ReferenceArrayField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';

import DialogContainer from 'components/DialogContainer';
import AddSubItem from 'components/SimpleForm/AddSubtem';
import { TrialAppForm } from './TrialAppForm';

export const TrialAppsField: FC = () => {
  const t = useTranslate();
  const record = useRecordContext();

  return (
    <Grid item>
      <Typography variant="h6">{t('resources.units.section.trial_apps')}</Typography>
      <DialogContainer createForm={<TrialAppForm record={record} />} resource="app_trials">
        <ReferenceArrayField source="app_trial_ids" reference="app_trials">
          <Datagrid bulkActionButtons={false}>
            <ImageField label={false} source="icon.src" emptyText="" />
            <TextField source="translated_name" />
            <DateField source="valid_until" />
            <DateField source="created_at" />
            <Box>
              <DeleteWithConfirmButton mutationMode="optimistic" redirect={false} />
            </Box>
          </Datagrid>
          <Box display="flex" justifyContent="center" p={2}>
            <AddSubItem />
          </Box>
        </ReferenceArrayField>
      </DialogContainer>
    </Grid>
  );
};
