import { FC } from 'react';
import { Create, CreateProps } from 'react-admin';
import { UpdateChannelPutForm } from './components';

export const UpdateChannelCreate: FC<CreateProps> = () => {
  return (
    <Create>
      <UpdateChannelPutForm />
    </Create>
  );
};
