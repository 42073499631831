import { FC, PropsWithChildren, ReactElement } from 'react';
import { Button, Form, FormProps, SaveButton } from 'react-admin';
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface FormDialogProps extends FormProps {
  open: boolean;
  onClose: () => void;
  title: string | ReactElement<any>;
}

export const FormDialog: FC<PropsWithChildren<FormDialogProps>> = ({ children, title, open, onClose, ...props }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Form {...props}>
        <IconButton component="button" sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ pr: 7 }}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <SaveButton />
          <Button label="ra.action.cancel" onClick={onClose} variant="text" />
        </DialogActions>
      </Form>
    </Dialog>
  );
};
