import { FC } from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';

export const SoftwareComponentList: FC = () => {
  return (
    <List>
      <Datagrid bulkActionButtons={false} rowClick={false}>
        <TextField source="name" />
        <ReferenceField source="hardware_id" reference="hardware" emptyText="-">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="path" emptyText="-" />
      </Datagrid>
    </List>
  );
};
