import { Tab, TabProps, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { CollectionSubscriptionsField, CollectionsField, CollectionTrialsField } from '../UnitShowCollections';
import { AppPurchasesField, AppTrialsField } from '../UnitShowApps';

export const AppsTab = (props: Omit<TabProps, 'children'>) => {
  const translate = useTranslate();

  return (
    <Tab {...props}>
      <Typography variant="h6">{translate('resources.units.section.subscriptions')}</Typography>
      <CollectionSubscriptionsField
        label={false}
        source="collection_subscription_ids"
        reference="app_collection_subscriptions"
      />

      <Typography variant="h6">{translate('resources.units.section.app_collections')}</Typography>
      <CollectionsField label={false} source="collection_purchase_ids" reference="app_collection_defaults" />

      <Typography variant="h6">{translate('resources.units.section.purchased_apps')}</Typography>
      <AppPurchasesField label={false} source="app_purchase_ids" reference="app_purchases" />

      <Typography variant="h6">{translate('resources.units.section.trial_apps')}</Typography>
      <AppTrialsField label={false} source="app_trial_ids" reference="app_trials" />

      <Typography variant="h6">{translate('resources.units.section.trial_app_collections')}</Typography>
      <CollectionTrialsField label={false} source="collection_trial_ids" reference="app_collection_trials" />
    </Tab>
  );
};

AppsTab.defaultProps = {
  label: 'resources.units.tabs.apps',
  value: 'apps',
  path: 'apps',
};
