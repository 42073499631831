import polyglotI18nProvider from 'ra-i18n-polyglot';
import { TranslationMessages, resolveBrowserLocale } from 'react-admin';
import { en } from './en';
import { nl } from './nl';

const messages: { [key: string]: TranslationMessages } = {
  en,
  nl,
};

let missingKeys: FlatStructure = {};

export const i18nProvider = polyglotI18nProvider(
  //@ts-ignore
  (locale) => {
    if (!messages[locale]) locale = 'en';
    return {
      ...messages[locale],
      _: (key: string, ...args: any[]) => {
        //@ts-ignore
        missingKeys[key] = key;
        return key;
      },
    };
  },
  resolveBrowserLocale(),
  [
    { locale: 'en', name: 'English' },
    { locale: 'nl', name: 'Nederlands' },
  ],
  {
    allowMissing: false,
    onMissingKey: (key: string) => {
      //@ts-ignore
      missingKeys[key] = key;
    },
  },
);
interface FlatStructure {
  [key: string]: string;
}

interface HierarchicalStructure {
  [key: string]: HierarchicalStructure | string;
}

function convertToHierarchy(obj: FlatStructure): HierarchicalStructure {
  const result: HierarchicalStructure = {};

  for (const key in obj) {
    const keys = key.split('.');
    let current: HierarchicalStructure | string = result;

    for (let i = 0; i < keys.length; i++) {
      if (i === keys.length - 1) {
        (current as HierarchicalStructure)[keys[i]] = obj[key];
      } else {
        if (typeof current[keys[i]] === 'string' || typeof current[keys[i]] === 'undefined') {
          current[keys[i]] = {};
        }
        current = current[keys[i]] as HierarchicalStructure;
      }
    }
  }
  return result;
}

// Only in development mode and every 10 seconds
if (process.env.NODE_ENV === 'development') {
  setInterval(() => console.debug(JSON.stringify(convertToHierarchy(missingKeys))), 10 * 1000);
}
