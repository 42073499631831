import { UserMenu } from 'layout';
import { AppBar, AppBarProps } from 'react-admin';
import { Grid, Typography } from '@mui/material';

const CustomAppBar = (props: AppBarProps) => {
  // FIXME: responsiveness https://github.com/marmelab/react-admin/blob/master/examples/demo/src/layout/AppBar.tsx
  return (
    <AppBar {...props} color="primary" userMenu={<UserMenu />}>
      <Grid flexGrow={1} alignItems="center" display="grid" gridTemplateColumns="repeat(3,1fr)">
        <Grid item>
          <Typography component="strong" flexGrow={0} id="react-admin-title" />
        </Grid>
        <Grid item display="flex" justifyContent="center" alignItems="center">
          <img
            src="/ToverView.svg"
            alt="ToverView logo"
            style={{
              flexGrow: 1,
              width: '100%',
              height: '30px',
              maxHeight: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        </Grid>
        <Grid item>
          <></>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default CustomAppBar;
