import { InvertColors as FlavourIcon } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { SubMenu } from 'constants/SubMenu';

import { FlavourCreate } from './FlavourCreate';
import { FlavourEdit } from './FlavourEdit';
import { FlavourList } from './FlavourList';
import { FlavourShow } from './FlavourShow';

const FlavourResource: ResourceProps = {
  name: 'flavours',
  icon: FlavourIcon,
  list: FlavourList,
  show: FlavourShow,
  edit: FlavourEdit,
  create: FlavourCreate,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_flavours'),
    menu: SubMenu.Configuration,
  },

  recordRepresentation: (record) => {
    return record.name;
  },
};

export default FlavourResource;
