import { FC } from 'react';
import { Datagrid, List, ListProps, TextField } from 'react-admin';

export const HardwareList: FC<ListProps> = () => (
  <List perPage={25}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="short_name" />
    </Datagrid>
  </List>
);
