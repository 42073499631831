import { ResourceProps } from 'react-admin';
import { Description as ReportIcon } from '@mui/icons-material';
import ReportList from './ReportList';
import ReportShow from './ReportShow';

const ReportResource: ResourceProps = {
  name: 'reports',
  icon: ReportIcon,
  list: ReportList,
  show: ReportShow,
};

export default ReportResource;
