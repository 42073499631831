import { FC } from 'react';
import { AutocompleteInput, TextInput, required, useGetList } from 'react-admin';
import _ from 'lodash';

import { AppOptionRenderer } from './AppOptionRenderer';
import { DurationInput } from './DurationInput';

interface TrialAppFormProps {
  record?: any;
}

export const TrialAppForm: FC<TrialAppFormProps> = ({ record }) => {
  const { data } = useGetList('apps');
  const allApps = data?.map((d) => d.id);
  const configurationId = record?.configuration_key;
  const uniqApps = _.difference(allApps, record.apps);

  const AppInput = () => {
    const { data, isLoading } = useGetList('apps', {
      filter: { id: uniqApps },
    });

    return (
      <AutocompleteInput
        source="app_id"
        choices={data}
        isLoading={isLoading}
        validate={required()}
        optionText={<AppOptionRenderer />}
        inputText={(option) => option.translated_name}
        matchSuggestion={(filter, choice) => choice.translated_name.toLowerCase().includes(filter.toLowerCase())}
      />
    );
  };

  return (
    <div>
      <AppInput />
      <DurationInput dataSource="trial_durations" validate={required()} />
      <TextInput
        source="configuration_id"
        type="hidden"
        sx={{ display: 'none' }}
        label={false}
        defaultValue={configurationId}
      />
    </div>
  );
};
