import { AutocompleteArrayInput, Edit, ReferenceArrayInput, TextInput } from 'react-admin';
import { Grid } from '@mui/material';

import SimpleForm from 'components/SimpleForm';

const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <Grid container direction="column" xs={12} lg={6} xl={4}>
        <TextInput source="name" />
        <TextInput source="email" type="email" />
        <ReferenceArrayInput source="roles" reference="roles">
          <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
