import { FC } from 'react';
import { BooleanField, Datagrid, List, ListProps, SearchInput, TextField } from 'react-admin';

const DesignationListFilters = [<SearchInput source="q" alwaysOn name="q" />];

export const DesignationList: FC<ListProps> = () => (
  <List perPage={25} filters={DesignationListFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <BooleanField source="default" />
    </Datagrid>
  </List>
);
