import { FC } from 'react';
import {
  ChipField,
  EmailField,
  ReferenceArrayField,
  Show,
  ShowProps,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from 'react-admin';

const UserShow: FC<ShowProps> = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <EmailField source="email" />
      <ReferenceArrayField reference="roles" source="roles" emptyText="-">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
