import { ChipField, ReferenceArrayField, Show, SimpleShowLayout, SingleFieldList, TextField } from 'react-admin';

const RoleShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" emptyText="-" />
      <ReferenceArrayField source="permission_ids" reference="permissions" sx={{ my: 1 }} emptyText="-">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

export default RoleShow;
