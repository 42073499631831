import { AppShortcut as AppIcon } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { SubMenu } from 'constants/SubMenu';

import AppCreate from './AppCreate';
import AppEdit from './AppEdit';
import AppList from './AppList';
import AppShow from './AppShow';

const AppResource: ResourceProps = {
  name: 'apps',
  icon: AppIcon,
  list: AppList,
  show: AppShow,
  edit: AppEdit,
  create: AppCreate,
  options: {
    isVisible: (hasPermission: Function) => hasPermission('manage_apps'),
    menu: SubMenu.AppsAndCollections,
  },
  recordRepresentation: (record) => {
    return record.name;
  },
};

export default AppResource;
