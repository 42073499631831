import {
  Datagrid,
  DateField,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  ShowButton,
  TextField,
  useRecordContext,
} from 'react-admin';

export const CollectionsField = (
  props: any = {
    label: 'App Collections',
    reference: 'collections',
    source: 'app_collection_ids',
  },
) => {
  const record = useRecordContext();

  return (
    <ReferenceArrayField
      source={props.source}
      reference={props.reference}
      label={props.label}
      emptyText=""
      record={record}
    >
      <Datagrid size="medium" bulkActionButtons={false} empty={<>-</>}>
        <ImageField
          label={false}
          source="icon.src"
          sx={{
            '& img': { width: '40px !important', height: '40px !important' },
          }}
          emptyText=""
        />
        <ReferenceField reference="collections" source="app_collection_id" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="unlock_key" />
        <DateField source="created_at" />
        <ReferenceField reference="collections" source="app_collection_id" link="show">
          <ShowButton />
        </ReferenceField>
      </Datagrid>
    </ReferenceArrayField>
  );
};
