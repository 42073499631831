import { ShowButton, DeleteButton, SaveButton } from 'react-admin';
import { Grid } from '@mui/material';

export const FormToolbar = () => {
  return (
    <Grid container spacing={4} sx={{ mt: 0, zIndex: 1 }}>
      <Grid item display="flex" flex={1} alignItems="center">
        <SaveButton />
      </Grid>
      <Grid item>
        <ShowButton />
        <DeleteButton />
      </Grid>
    </Grid>
  );
};
