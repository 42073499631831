import { FC } from 'react';
import { FormDataConsumer, ReferenceInput, SelectInput, TextInput } from 'react-admin';

import { Grid } from '@mui/material';
import SimpleForm, { SimpleFormProps } from 'components/SimpleForm';

export const UpdateChannelPutForm: FC<Omit<SimpleFormProps, 'children'>> = (props) => {
  return (
    <SimpleForm {...props}>
      <Grid container>
        <TextInput source="name" fullWidth />
        <TextInput source="short_name" fullWidth />
        <ReferenceInput source="hardware_id" reference="hardware">
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.hardware_id && (
              <ReferenceInput
                source="hardware_revision_id"
                reference="hardware_revisions"
                filter={{ hardware_id: formData.hardware_id }}
              >
                <SelectInput optionText="name" fullWidth />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      </Grid>
    </SimpleForm>
  );
};
