import { FC } from 'react';
import { Button, Datagrid, List, ListProps, TextField, useDataProvider, useRecordContext } from 'react-admin';
import { useMutation } from 'react-query';
import { Download } from '@mui/icons-material';

const DownloadButton: FC = () => {
  const report = useRecordContext();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(['download', report.href], () => dataProvider.download(report.href));

  return report.href ? (
    <Button
      alignIcon="left"
      variant="text"
      color="secondary"
      startIcon={<Download />}
      label="custom.actions.download"
      onClick={(event) => {
        event.stopPropagation();
        mutate();
      }}
      disabled={isLoading}
    />
  ) : null;
};

const ReportList: FC<ListProps> = () => {
  return (
    <List perPage={25} exporter={false}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <DownloadButton />
      </Datagrid>
    </List>
  );
};

export default ReportList;
