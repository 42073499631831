import React, { FC } from 'react';
import {
  Edit,
  Labeled,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ShowProps,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

const UpdateChannelInput = () => {
  const hardwareSelected = useWatch({ name: 'hardware_id' });
  const hardwareRevisionId = useWatch({ name: 'id' });
  return (
    hardwareSelected && (
      <>
        <ReferenceInput
          source="update_channel_id"
          reference="update_channels"
          filter={{ hardware_id: hardwareSelected, hardware_revision_id: hardwareRevisionId }}
        >
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
      </>
    )
  );
};

export const HardwareRevisionEdit: FC<ShowProps> = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" />
      <Labeled>
        <ReferenceField source="hardware_id" reference="hardware" emptyText="-">
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
      <UpdateChannelInput />
    </SimpleForm>
  </Edit>
);
