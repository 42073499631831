import React, { ReactElement } from 'react';
import { RaRecord } from 'react-admin';

interface DialogContextProps {
  isEditing?: boolean;
  isCreating?: boolean;
  record?: any;
  resource: string;
  createForm?: ReactElement;
  editForm?: ReactElement;
  openCreateDialog: () => void;
  openEditDialog: (record: RaRecord) => void;
  closeDialog: () => void;
  setRecord: (record?: RaRecord) => void;
  label: string;
}

const DialogContext = React.createContext<DialogContextProps>({
  isEditing: false,
  isCreating: false,
  record: {},
  resource: '',
  createForm: <></>,
  editForm: <></>,
  openCreateDialog: () => {},
  openEditDialog: (record: RaRecord) => {
    console.log('openEditDialog', record);
  },
  closeDialog: () => {},
  setRecord: () => {},
  label: '',
});

DialogContext.displayName = 'DialogContext';

export default DialogContext;
