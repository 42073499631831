import { FC } from 'react';
import { ReferenceField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';

export const LocaleShow: FC<ShowProps> = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" emptyText="-" />
      <ReferenceField reference="locales" source="fallback_id">
        <TextField source="name" emptyText="-" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
