import { useEffect } from 'react';
import { Loading, useGetOne } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';

const AssetToUnit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: unit, isLoading } = useGetOne('units', { id });

  useEffect(() => {
    if (!isLoading && unit) {
      navigate(`/units/${unit.id}/show`);
    }
  }, [isLoading, unit, navigate]);

  return <Loading />;
};

export default AssetToUnit;
