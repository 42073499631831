import { FC } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  RaRecord,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useGetOne,
  useRecordContext,
  WithRecord,
} from 'react-admin';
import { useMutation } from 'react-query';
import { Download } from '@mui/icons-material';

const DownloadButton: FC<RaRecord> = (record) => {
  const report = useRecordContext();
  const dataProvider = useDataProvider();
  const { data } = useGetOne('reports', { id: record.id });
  const downloadUrl = data.download_link + '/' + report.value;
  const { mutate, isLoading } = useMutation(['download', downloadUrl], () => dataProvider.download(downloadUrl));

  return isLoading ? null : (
    <Button
      alignIcon="left"
      variant="text"
      color="secondary"
      startIcon={<Download />}
      label="custom.actions.download"
      onClick={(e) => {
        e.stopPropagation();
        mutate();
      }}
      disabled={isLoading}
    />
  );
};

const ReportShow: FC<ShowProps> = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="name" />
        <WithRecord
          label="custom.labels.options"
          render={(record) => (
            <ArrayField source="options">
              <Datagrid bulkActionButtons={false}>
                <TextField source="label" />
                <DownloadButton {...record} />
              </Datagrid>
            </ArrayField>
          )}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default ReportShow;
