import { FC } from 'react';
import { Datagrid, List, ListProps, ReferenceInput, SearchInput, SelectInput, TextField } from 'react-admin';
import { ColorField } from 'react-admin-color-picker';

const FlavourListFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="default_app_collection_id" reference="collections">
    <SelectInput source="name" optionText={(option) => option.name} emptyText="All" />
  </ReferenceInput>,
];

export const FlavourList: FC<ListProps> = () => (
  <List perPage={25} filters={FlavourListFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <ColorField source="color" />
    </Datagrid>
  </List>
);
