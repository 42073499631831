import { FC } from 'react';
import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';

import DialogContainer from 'components/DialogContainer';
import AddSubItem from 'components/SimpleForm/AddSubtem';
import { TrialCollectionsForm } from './TrialCollectionsForm';

export const TrialCollectionsField: FC = () => {
  const record = useRecordContext();
  const t = useTranslate();

  return (
    <Grid item>
      <Typography variant="h6">{t('resources.units.section.trial_app_collections')}</Typography>
      <DialogContainer createForm={<TrialCollectionsForm record={record} />} resource="app_collection_trials">
        <ReferenceArrayField source="collection_trial_ids" reference="app_collection_trials">
          <Datagrid bulkActionButtons={false}>
            <ImageField label={false} source="icon.src" emptyText="" />
            <ReferenceField source="app_collection_id" reference="collections">
              <TextField source="name" />
            </ReferenceField>
            <DateField source="valid_until" />
            <DateField source="created_at" />
            <Box>
              <DeleteWithConfirmButton mutationMode="optimistic" redirect={false} />
            </Box>
          </Datagrid>
          <Box display="flex" justifyContent="center" p={2}>
            <AddSubItem />
          </Box>
        </ReferenceArrayField>
      </DialogContainer>
    </Grid>
  );
};
