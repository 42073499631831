import { Datagrid, DateField, List, SortPayload, Tab, TabProps, TextField, useRecordContext } from 'react-admin';
import React from 'react';
import { MorphField } from '../../../../components/Fields/MorphField';

const PropertiesPanel = () => {
  const record = useRecordContext();
  return (
    <>
      <table>
        {Object.keys(record.properties.old || record.properties.attributes)
          .sort()
          .map((key) => (
            <tr>
              <td>
                <strong>{key}:</strong>
              </td>
              <td>
                {record.properties.old && record.properties.old[key] ? JSON.stringify(record.properties.old[key]) : ''}
              </td>
              <td>=&gt;</td>
              <td>{JSON.stringify(record.properties.attributes[key])}</td>
            </tr>
          ))}
      </table>
    </>
  );
};

const sort: SortPayload = { field: 'created_at', order: 'DESC' };

const ConfigurationChangesSection = () => {
  const record = useRecordContext();

  return (
    <List hasCreate={false} resource={'unit/' + record.configuration_key + '/activity_logs'} sort={sort}>
      <Datagrid bulkActionButtons={false} expand={<PropertiesPanel />} isRowExpandable={(row) => row.properties}>
        <DateField showTime source="created_at" />
        <TextField source="subject_type" />
        <TextField source="description" />
        <MorphField morph="causer" label="Causer" />
      </Datagrid>
    </List>
  );
};

export const ActivityLogTab = (props: Omit<TabProps, 'children'>) => {
  return (
    <Tab {...props}>
      <ConfigurationChangesSection />
    </Tab>
  );
};

ActivityLogTab.defaultProps = {
  label: 'resources.units.tabs.activity_log',
  value: 'activity_log',
  path: 'activity_log',
};
