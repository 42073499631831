import RepartitionIcon from '@mui/icons-material/Repartition';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

interface Props {
  value?: number;
}

const NewlyRegisteredUnits = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  return (
    <CardWithIcon
      to="/commands"
      icon={RepartitionIcon}
      title={translate('resources.dashboard.newly_registered_units', { _: 'Newly registered units', smart_count: 1 })}
      subtitle={value}
    />
  );
};

export default NewlyRegisteredUnits;
