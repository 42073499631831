import { FC } from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  ImageField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import FlavourChipField from '../flavours/FlavourChipField';
import IconField from '../../components/Fields/IconField';
import HardwareIcon from '../hardware/icons/HardwareIcon';

export const CollectionShow: FC<ShowProps> = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="resources.collections.tabs.general">
        <ImageField source="icon.src" label="custom.labels.icon" />
        <TextField source="name" />
        <ReferenceField source="flavour_id" reference="flavours" emptyText="-" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField source="designation_ids" reference="designations" emptyText="-" sx={{ mt: 1 }}>
          <SingleFieldList linkType="show">
            <ChipField source="name" emptyText="-" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField source="available_as_subscription" />
        <BooleanField source="unlockable" />
      </Tab>
      <Tab label="resources.collections.tabs.apps">
        <ReferenceArrayField source="app_ids" reference="apps" emptyText="-">
          <Datagrid bulkActionButtons={false}>
            <IconField source="icon.src" />
            <TextField source="translated_name" />
            <NumberField source="cognitive" />
            <ReferenceArrayField source="flavour_ids" reference="flavours" emptyText="-">
              <SingleFieldList linkType="show">
                <FlavourChipField />
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField source="hardware_ids" reference="hardware" emptyText="-">
              <SingleFieldList linkType="show">
                <HardwareIcon source="name" />
                {/*<ChipField source="name" />*/}
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField source="display_surface_ids" reference="display_surfaces" emptyText="-">
              <SingleFieldList linkType="show">
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
