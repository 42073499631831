// const Dashboard = () => {
//   const { hasPermission } = usePermission();
//   if (!hasPermission('view_dashboard')) return null;

//   return (
//     <Paper>
//       <Card>
//         <CardHeader title="Units are online in last 24 hours" />
//         <CardContent>24</CardContent>
//       </Card>
//       <Card sx={{ height: '100%' }}>
//         <CardHeader title="Latest errors" />
//         <CardContent>
//           <List resource="telescope/requests">
//             <Datagrid rowClick="show">
//               <TextField source="content.method" label="Method" sortable={false} />
//               <TruncatedTextField source="content.uri" maxLength={40} label="Path" />
//               <ResponseStatusField label="Status" />
//               <TextField source="content.duration" label="Duration" />
//               <TimeAgoField source="created_at" label="Happened" />
//             </Datagrid>
//           </List>
//         </CardContent>
//       </Card>
//     </Paper>
//   );
// };

// export default Dashboard;
import { Card, CardContent, CardHeader, Theme, useMediaQuery } from '@mui/material';
import ResponseStatusField from 'components/ResponseStatusField';
import TimeAgoField from 'components/TimeAgoField';
import TruncatedTextField from 'components/TruncatedTextField';
import { usePermission } from 'providers/auth/permissionProvider';
import { CSSProperties } from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import LastOnlineUnits from './LastOnlineUnits';
import MissingUnits from './MissingUnits';
import NewlyRegisteredUnits from './NewlyRegisteredUnits';

// import Welcome from './Welcome';
// import MonthlyRevenue from './MonthlyRevenue';
// import NbNewOrders from './NbNewOrders';
// import PendingOrders from './PendingOrders';
// import PendingReviews from './PendingReviews';
// import NewCustomers from './NewCustomers';
// import OrderChart from './OrderChart';

// import { Order } from '../types';

// interface OrderStats {
//   revenue: number;
//   nbNewOrders: number;
//   pendingOrders: Order[];
// }

// interface State {
//   nbNewOrders?: number;
//   pendingOrders?: Order[];
//   recentOrders?: Order[];
//   revenue?: string;
// }

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

// @TODO stays like this for now as backbone, we can add items later - Ferenc
const Dashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { hasPermission } = usePermission();
  if (!hasPermission('view_dashboard')) return null;

  //const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);

  // const { data: orders } = useGetList<Order>('commands', {
  //   filter: { date_gte: aMonthAgo.toISOString() },
  //   sort: { field: 'date', order: 'DESC' },
  //   pagination: { page: 1, perPage: 50 },
  // });

  // const aggregation = useMemo<State>(() => {
  //   if (!orders) return {};
  //   const aggregations = orders
  //     .filter((order) => order.status !== 'cancelled')
  //     .reduce(
  //       (stats: OrderStats, order) => {
  //         if (order.status !== 'cancelled') {
  //           stats.revenue += order.total;
  //           stats.nbNewOrders++;
  //         }
  //         if (order.status === 'ordered') {
  //           stats.pendingOrders.push(order);
  //         }
  //         return stats;
  //       },
  //       {
  //         revenue: 0,
  //         nbNewOrders: 0,
  //         pendingOrders: [],
  //       },
  //     );
  //   return {
  //     recentOrders: orders,
  //     revenue: aggregations.revenue.toLocaleString(undefined, {
  //       style: 'currency',
  //       currency: 'USD',
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0,
  //     }),
  //     nbNewOrders: aggregations.nbNewOrders,
  //     pendingOrders: aggregations.pendingOrders,
  //   };
  // }, [orders]);

  //const { nbNewOrders, pendingOrders, revenue, recentOrders } = aggregation;
  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <LastOnlineUnits value={24} />
        <VerticalSpacer />
        <NewlyRegisteredUnits value={24} />
        <VerticalSpacer />
        <TelescopeCard />

        {/* <VerticalSpacer />
        <VerticalSpacer />
        <PendingOrders orders={pendingOrders} /> */}
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>{/* <Welcome /> */}</div>
      <div style={styles.flex}>
        <LastOnlineUnits value={24} />
        <Spacer />
        <NewlyRegisteredUnits value={24} />
        <Spacer />
        <MissingUnits />
      </div>
      <div style={styles.singleCol}>
        <TelescopeCard />
      </div>
      {/* <div style={styles.singleCol}><OrderChart orders={recentOrders} /></div> */}
      {/* <div style={styles.singleCol}><PendingOrders orders={pendingOrders} /></div> */}
    </div>
  ) : (
    <>
      <div style={styles.flex}>
        <LastOnlineUnits value={24} />
        <Spacer />
        <NewlyRegisteredUnits value={24} />
        <Spacer />
        <MissingUnits />
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <Spacer />
            <Spacer />
            <Spacer />
          </div>
          <div style={styles.singleCol}>{/* <OrderChart orders={recentOrders} /> */}</div>
          <div style={styles.singleCol}>{/* <PendingOrders orders={pendingOrders} /> */}</div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex} />
        </div>
      </div>
      <VerticalSpacer />
      <TelescopeCard />
    </>
  );
};
const TelescopeCard = () => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Latest errors" />
      <CardContent>
        <List resource="telescope/requests">
          <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="content.method" label="Method" sortable={false} />
            <TruncatedTextField source="content.uri" maxLength={40} label="Path" />
            <ResponseStatusField label="Status" />
            {!isXSmall && <TextField source="content.duration" label="Duration" />}
            <TimeAgoField source="created_at" label="Happened" />
          </Datagrid>
        </List>
      </CardContent>
    </Card>
  );
};
export default Dashboard;
