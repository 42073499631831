import { useContext } from 'react';
import DialogContext from './DialogContext';

export const useDialogContext = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialogContext must be used within a DialogContextProvider');
  }
  return context;
};

export default useDialogContext;
